<template>
    <v-container fluid>
        <v-row class="text-center">
            <v-col cols="12">
                <!-- Header section -->
                <v-row :class="{ 'mt-10': !$vuetify.breakpoint.mdAndUp, 'mt-10 pt-10': $vuetify.breakpoint.mdAndUp }">
                    <v-col cols="12" class="d-flex justify-center">
                        <h1>Tabella Coordinate</h1>
                    </v-col>
                </v-row>
                <!-- Pulsante per aggiornare le coordinate delle righe selezionate-->
                <v-row v-if="selected.length > 0" class="mb-4">
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn color="primary" @click="updateSelectedCoordinates" :loading="updatingCoordinates">
                            <v-icon left>update</v-icon>
                            Aggiorna Coordinate ({{ selected.length }} selezionate)
                        </v-btn>
                    </v-col>
                </v-row>
                <!-- Sezione ricerca -->
                <v-row class="mb-4">
                    <v-col cols="12" sm="6" class="d-flex justify-center">
                        <v-autocomplete
                            v-model="selectedProvince"
                            :items="provinces"
                            :loading="loadingProvince"
                            :search-input.sync="searchProvince"
                            item-text="name"
                            item-value="id"
                            label="Cerca provincia"
                            outlined
                            dense
                            chips
                            small-chips
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex justify-center">
                        <v-autocomplete
                            v-model="selectedCity"
                            :items="cities"
                            :loading="loadingCity"
                            :search-input.sync="searchCity"
                            item-text="name"
                            item-value="id"
                            label="Cerca città"
                            outlined
                            dense
                            chips
                            small-chips
                        >
                            <!-- Template per mostrare la provincia nel menu -->
                            <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ data.item.province }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <!-- Tabella dati -->
                <v-card class="mt-6">
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="items"
                        :loading="loading"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :footer-props="{
                            'items-per-page-options': [10, 50, 100],
                            'items-per-page-text': 'Righe per pagina:',
                            'show-first-last-page': true
                        }"
                        show-select
                        item-key="id"
                        class="elevation-1"
                    >
                        <!-- Template per la colonna CAP -->
                        <template #[`item.caps`]="{ item }">
                            <div>
                                <!-- Mostra primi 3 CAP -->
                                <div v-if="item.caps.length < 3">{{ item.caps.slice(0, 3).join(', ') }}</div>

                                <!-- Se ci sono più di 3 CAP, mostra il pulsante -->
                                <v-btn v-if="item.caps.length > 3" small text color="primary" @click="showAllCaps(item)"> Vedi tutti </v-btn>
                            </div>
                        </template>

                        <!-- Template per la colonna sinonimi provincie -->
                        <template #[`item.provinceSynonyms`]="{ item }">
                            <div>
                                <!-- Mostra primi 3 sinonimi -->
                                <span v-if="item.provinceSynonyms && item.provinceSynonyms.length <= 3">{{ item.provinceSynonyms.join(', ') }}</span>
                                <span v-if="!item.provinceSynonyms || item.provinceSynonyms.length === 0">-</span>

                                <span>
                                    <!-- Se ci sono più di 3 sinonimi, mostra il pulsante -->
                                    <v-btn
                                        v-if="item.provinceSynonyms && item.provinceSynonyms.length > 3"
                                        small
                                        text
                                        color="primary"
                                        @click="showAllProvinceSynonyms(item)"
                                    >
                                        Vedi tutti
                                    </v-btn>
                                </span>
                            </div>
                        </template>

                        <!-- Template per la colonna sinonimi citta -->
                        <template #[`item.citySynonyms`]="{ item }">
                            <div>
                                <!-- Mostra primi 3 sinonimi -->
                                <span v-if="item.citySynonyms && item.citySynonyms.length <= 3">{{ item.citySynonyms.join(', ') }}</span>
                                <span v-if="!item.citySynonyms || item.citySynonyms.length === 0">-</span>

                                <span>
                                    <!-- Se ci sono più di 3 sinonimi, mostra il pulsante -->
                                    <v-btn
                                        v-if="item.citySynonyms && item.citySynonyms.length > 3"
                                        small
                                        text
                                        color="primary"
                                        @click="showAllCitySynonyms(item)"
                                    >
                                        Vedi tutti
                                    </v-btn>
                                </span>
                            </div>
                        </template>

                        <!-- Aggiunta colonna "azione" -->
                        <template #[`item.id`]="{ value }">
                            <router-link :to="`/updateCoordinate/${value}/edit`">
                                <v-icon class="pt-1 d-inline-flex"> edit </v-icon>
                            </router-link>

                            <v-icon class="d-inline-flex pl-5" @click="updateSingleCoordinate(value)">update</v-icon>
                        </template>
                    </v-data-table>
                </v-card>

                <!-- Dialog per mostrare tutti i CAP -->
                <v-dialog v-model="dialogCaps" max-width="500px">
                    <v-card>
                        <v-card-title>Tutti i CAP</v-card-title>
                        <v-card-text>
                            {{ selectedCaps.join(', ') }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogCaps = false">Chiudi</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- Dialog sinonimi delle province -->
                <v-dialog v-model="dialogProvinceSynonyms" max-width="500px">
                    <v-card>
                        <v-card-title>Tutti i sinonimi della provincia</v-card-title>
                        <v-card-text>
                            {{ selectedProvinceSynonyms.join(', ') }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogProvinceSynonyms = false">Chiudi</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!--  dialog sinonimi delle città -->
                <v-dialog v-model="dialogCitySynonyms" max-width="500px">
                    <v-card>
                        <v-card-title>Tutti i sinonimi della città</v-card-title>
                        <v-card-text>
                            {{ selectedCitySynonyms.join(', ') }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogCitySynonyms = false">Chiudi</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- Dialog per l'aggiornamento delle coordinate multiple -->
                <v-dialog v-model="progressDialog" persistent max-width="500px">
                    <v-card>
                        <v-card-title>Aggiornamento coordinate in corso</v-card-title>
                        <v-card-text>
                            <p>Aggiornamento {{ currentUpdateIndex + 1 }} di {{ selected.length }}</p>
                            <p>Elaborazione: {{ currentProcessingItem }}</p>
                            <v-progress-linear :value="(currentUpdateIndex / selected.length) * 100" color="primary" striped height="25">
                                {{ Math.round((currentUpdateIndex / selected.length) * 100) }}%
                            </v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'updateCoordinates',
    data() {
        return {
            headers: [
                {
                    text: 'Azioni',
                    value: 'id',
                    sortable: false,
                    align: 'start'
                },
                {
                    text: 'Regione',
                    value: 'region',
                    sortable: false
                },

                {
                    text: 'Provincia',
                    value: 'province',
                    sortable: false
                },
                {
                    text: 'Synonimo provincia',
                    value: 'provinceSynonyms',
                    sortable: false
                },
                {
                    text: 'Città',
                    value: 'city',
                    sortable: false
                },
                {
                    text: 'CAP',
                    value: 'caps',
                    sortable: false
                },
                {
                    text: 'Synonimo città',
                    value: 'citySynonyms',
                    sortable: false
                }
            ],
            // Dati della tabella
            items: [],
            loading: true,
            totalItems: 0,
            // Opzioni per la paginazione
            options: {
                page: 1,
                itemsPerPage: 10
            },

            // Gestione dialog CAP
            dialogCaps: false,
            selectedCaps: [],

            // Dati delle province e città
            provinces: [],
            cities: [],
            //campo ricerca provincia
            searchProvince: null,
            selectedProvince: null,
            loadingProvince: false,
            //  sinonimi delle citta
            dialogCitySynonyms: false,
            selectedCitySynonyms: [],
            //  sinonimi delle province
            dialogProvinceSynonyms: false,
            selectedProvinceSynonyms: [],

            //campo ricerca citta
            searchCity: null,
            selectedCity: null,
            loadingCity: false,
            timeoutSearch: null,

            // Nuovi dati per la selezione multipla
            selected: [],
            updatingCoordinates: false,
            progressDialog: false,
            currentUpdateIndex: 0,
            currentProcessingItem: ''
        }
    },
    async created() {
        // Carica i dati iniziali quando il componente viene creato
        await this.getDataFromApi()
    },
    watch: {
        // Osserva cambiamenti nelle opzioni della tabella
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true
        },
        searchProvince(val) {
            if (val && val.length > 2) {
                this.getProvinces(val)
            }
        },

        // Watch per la ricerca delle città
        searchCity(val) {
            if (val && val.length > 2) {
                this.getCities(val)
            }
        },

        // Watch per quando viene selezionato un valore
        selectedProvince() {
            this.handleSearch()
        },
        selectedCity() {
            this.handleSearch()
        }
    },

    methods: {
        //aggiorna coordinate
        async updateSingleCoordinate(id) {
            console.log('Aggiornamento coordinate per ID:', id)
            // Troviamo la città corrispondente nel nostro array items
            const city = this.items.find((item) => item.id == id)
            if (!city) {
                this.$notify({
                    group: 'notifica',
                    title: 'Errore',
                    text: 'Città non trovata',
                    type: 'error'
                })
                return
            }

            await this.updateCoordinatesForCity(city)
        },
        // Metodo per aggiornare le coordinate di tutte le città selezionate
        async updateSelectedCoordinates() {
            if (this.selected.length == 0) {
                this.$notify({
                    group: 'notifica',
                    title: 'Attenzione',
                    text: 'Nessuna città selezionata',
                    type: 'warning'
                })
                return
            }

            this.updatingCoordinates = true
            this.progressDialog = true
            this.currentUpdateIndex = 0

            // Creare una copia delle città selezionate per evitare problemi con la reattività
            const selectedItems = [...this.selected]

            try {
                // Aggiorna le coordinate una città alla volta
                for (let i = 0; i < selectedItems.length; i++) {
                    const city = selectedItems[i]
                    this.currentUpdateIndex = i
                    this.currentProcessingItem = `${city.city} (${city.province})`

                    // Aggiorna le coordinate sia della città che della provincia
                    await this.updateCoordinatesForCity(city)

                    // Breve pausa per non sovraccaricare l'API
                    await new Promise((resolve) => setTimeout(resolve, 500))
                }

                this.$notify({
                    group: 'notifica',
                    title: 'Successo',
                    text: `Coordinate aggiornate per ${selectedItems.length} città`,
                    type: 'success'
                })

                // Ricarica i dati per mostrare le coordinate aggiornate
                await this.getDataFromApi()
            } catch (error) {
                console.error("Errore nell'aggiornamento delle coordinate multiple:", error)
                this.$notify({
                    group: 'notifica',
                    title: 'Errore',
                    text: "Si è verificato un errore durante l'aggiornamento di alcune coordinate",
                    type: 'error'
                })
            } finally {
                this.updatingCoordinates = false
                this.progressDialog = false
            }
        },

        // Metodo per aggiornare le coordinate di una singola città e della sua provincia
        async updateCoordinatesForCity(city) {
            try {
                // 1. Aggiorna coordinate della città
                const cityResponse = await this.$http.post(`location`, {
                    type: 'addr',
                    data: {
                        city: city.city,
                        province: city.province,
                        country: 'Italy'
                    }
                })

                if (cityResponse?.status === 'OK') {
                    // Aggiorna la città nel database
                    await this.$http.put(`cities/${city.id}`, {
                        data: {
                            latitude: cityResponse.result.lat.toString(),
                            longitude: cityResponse.result.lng.toString()
                        }
                    })

                    // 2. Aggiorna coordinate della provincia
                    // Prima ottieni l'ID della provincia
                    const cityDetails = await this.$http.get(`cities/${city.id}`, {
                        populate: ['Province']
                    })

                    const provinceId = cityDetails.data.attributes.Province.data.id

                    const provinceResponse = await this.$http.post(`location`, {
                        type: 'addr',
                        data: {
                            city: city.province,
                            province: city.region,
                            country: 'Italy'
                        }
                    })

                    if (provinceResponse?.status === 'OK') {
                        // Aggiorna la provincia nel database
                        await this.$http.put(`provinces/${provinceId}`, {
                            data: {
                                latitude: provinceResponse.result.lat.toString(),
                                longitude: provinceResponse.result.lng.toString()
                            }
                        })
                    }

                    return true
                } else {
                    console.warn(`Coordinate non trovate per ${city.city}, ${city.province}`)
                    return false
                }
            } catch (error) {
                console.error(`Errore nell'aggiornamento delle coordinate per ${city.city}:`, error)
                throw error
            }
        },

        //campo ricerca
        handleSearch() {
            // Debounce per evitare troppe chiamate
            if (this.timeoutSearch) clearTimeout(this.timeoutSearch)
            this.timeoutSearch = setTimeout(() => {
                this.options.page = 1 // Reset della pagina
                this.getDataFromApi()
            }, 300)
        },
        // suggerimenti province nell'autocomplete
        async getProvinces(search) {
            if (this.timeoutSearch) clearTimeout(this.timeoutSearch)

            this.timeoutSearch = setTimeout(async () => {
                this.loadingProvince = true
                try {
                    const response = await this.$http.get('provinces', {
                        'pagination[pageSize]': 100,
                        'filters[Name][$containsi]': search,
                        'sort': 'Name:asc' // ordina alfabeticamente
                    })

                    this.provinces = response.data.map((province) => ({
                        id: province.id,
                        name: province.attributes.Name
                    }))
                } catch (error) {
                    console.error('Errore nel caricamento suggerimenti province:', error)
                } finally {
                    this.loadingProvince = false
                }
            }, 300)
        },

        //  suggerimenti delle città nell'autocomplete
        async getCities(search) {
            if (this.timeoutSearch) clearTimeout(this.timeoutSearch)

            this.timeoutSearch = setTimeout(async () => {
                this.loadingCity = true
                try {
                    let filters = {
                        'filters[Name][$containsi]': search
                    }

                    // Se è selezionata una provincia, filtra le città per quella provincia
                    if (this.selectedProvince) {
                        filters['filters[Province][id][$eq]'] = this.selectedProvince
                    }

                    const response = await this.$http.get('cities', {
                        'pagination[pageSize]': 100,
                        'populate': ['Province'],
                        'sort': 'Name:asc',
                        ...filters
                    })

                    this.cities = response.data.map((city) => ({
                        id: city.id,
                        name: city.attributes.Name,
                        province: city.attributes.Province.data.attributes.Name
                    }))
                } catch (error) {
                    console.error('Errore nel caricamento suggerimenti città:', error)
                } finally {
                    this.loadingCity = false
                }
            }, 300)
        },
        // Metodo per caricare i dati nella tabella
        async getDataFromApi() {
            this.loading = true
            const { page, itemsPerPage, sortBy, sortDesc } = this.options

            try {
                let filters = {}

                if (this.selectedProvince) {
                    filters['filters[Province][id][$eq]'] = this.selectedProvince
                }
                if (this.selectedCity) {
                    filters['filters[id][$eq]'] = this.selectedCity
                }
                // chiamata alle citta che include tutti i dati
                const response = await this.$http.get('cities', {
                    'pagination[pageSize]': itemsPerPage,
                    'pagination[page]': page,
                    'populate': ['Province', 'Province.synonym', 'synonym'],
                    ...filters
                })
                // formatto i dati per la tabella
                this.items = response.data.map((city) => {
                    // Estrai i sinonimi delle citta
                    const citySynonyms = city.attributes.synonym || []
                    const citySynonymNames = citySynonyms.map((s) => s.Name || '-').filter(Boolean)
                    // Estrai i sinonimi della provincia
                    const provinceSynonyms = city.attributes.Province.data.attributes.synonym || []
                    const provinceSynonymNames = provinceSynonyms.map((s) => s.Name || '-').filter(Boolean)

                    return {
                        id: city.id,
                        city: city.attributes.Name,
                        province: city.attributes.Province.data.attributes.Name,
                        region: city.attributes.Province.data.attributes.Regione,
                        caps: city.attributes.Cap || [],
                        citySynonyms: citySynonymNames,
                        provinceSynonyms: provinceSynonymNames
                    }
                })

                console.log(response, 'api')

                // Aggiorniamo il totale degli items per la paginazione
                this.totalItems = response.meta.pagination.total
                /*
                const p = await this.$http.get(`provinces`, {
                    'pagination[pageSize]': 200,
                    'populate': ['zones']
                })
                console.log(p, 'p')

                const c = await this.$http.get(`cities`, {
                    'pagination[pageSize]': 200,
                    'populate': ['Province']
                })
                console.log(c, 'c')

 */
            } catch (error) {
                console.error('Errore nel caricamento dei dati:', error)
                this.$notify({
                    group: 'notifica',
                    title: 'Errore',
                    text: 'Si è verificato un errore durante il caricamento dei dati',
                    type: 'error'
                })
            } finally {
                this.loading = false
            }
        },

        //  tutti i cap nel dialog
        showAllCaps(item) {
            this.selectedCaps = item.caps
            this.dialogCaps = true
        },
        // mostrare tutti i sinonimi delle città nel dialog
        showAllCitySynonyms(item) {
            this.selectedCitySynonyms = item.citySynonyms
            this.dialogCitySynonyms = true
        },
        // mostrare tutti i sinonimi delle provincie nel dialog
        showAllProvinceSynonyms(item) {
            this.selectedProvinceSynonyms = item.provinceSynonyms
            this.dialogProvinceSynonyms = true
        }
    }
}
</script>
