<template>
    <v-container>
        <loading v-model="loading" />

        <!-------------- TITOLO
                    ----------------->
        <v-row>
            <v-col cols="12">
                <h1 class="pt-16 text-center">
                    <span v-show="!editing && !newu">{{ $t('informazioni') }}</span>
                    <span v-show="newu">{{ $t('creazione') }}</span>
                    <span v-show="editing && !newu">{{ $t('modifica') }}</span>
                    {{ $tc('lead-da-controllare', 1) }}
                </h1>
            </v-col>
        </v-row>

        <!--------------  creazione/modifica agente
                         ----------------->
        <v-card class="overflow-hidden mt-4" color="primary" dark>
            <v-toolbar color="primary darken-1">
                <a @click="$router.go(-1)">
                    <v-icon class="mr-3 ml-4">arrow_back</v-icon>
                </a>

                <v-spacer></v-spacer>
                <v-btn v-show="editing" color="success" @click="convertLead" class="text-center" :disabled="loading"> {{ $t('converti-lead') }} </v-btn>

                <v-btn color="green " fab small @click="Edit(true)" v-show="!editing">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                    color="red "
                    class="ml-2"
                    fab
                    small
                    @click="showDeleteConfirmation = true"
                    v-show="!editing && !convDiag"
                    v-if="$permission($auth.utente.role.name, 'deleteLeadToCheck')"
                >
                    <v-icon>delete</v-icon>
                </v-btn>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <!-------------- <v-btn color="success" v-show="newu" @click="Create" :disabled="loading"> Crea </v-btn>  ----------------->
                    <v-btn color="success" v-show="editing && !newu" :disabled="loading" @click="Save"> {{ $t('salva') }} </v-btn>
                    <v-btn color="error" v-show="editing && !newu" @click="Edit(false)" :disabled="loading">
                        {{ $t('annulla') }}
                    </v-btn>
                </v-card-actions>
            </v-toolbar>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="lead.Name"
                                :label="$t('nome') + '*'"
                                :rules="[$rules('Required'), $rules('maxLength250')].flat()"
                                outlined
                                :readonly="!editing"
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="lead.Phone"
                                :label="$t('telefono')"
                                :rules="[$rules('maxLength250')].flat()"
                                outlined
                                :readonly="!editing"
                                validate-on-blur
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="lead.Email"
                                :label="$t('email')"
                                :rules="[$rules('email'), $rules('maxLength250')].flat()"
                                outlined
                                :readonly="!editing"
                                validate-on-blur
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="lead.Address"
                                :label="$t('indirizzo') + '*'"
                                :rules="[$rules('Required'), $rules('maxLength250')].flat()"
                                outlined
                                :readonly="!editing"
                                validate-on-blur
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field v-model="lead.Province" :label="$tc('provincia', 1) + '*'" outlined :readonly="!editing" hide-details></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="3">
                            <v-text-field v-model="lead.City" :label="$t('citta') + '*'" outlined :readonly="!editing" hide-details></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="3">
                            <v-text-field v-model="lead.CAP" :label="$t('cap') + '*'" outlined :readonly="!editing" hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <!--INDIRIZZO DA MODIFICARE-->
                    <v-form ref="convform" v-model="valid" lazy-validation>
                        <v-row v-show="editing">
                            <v-col cols="12"
                                ><h1 class="text-center">{{ $t('correzione-indirizzo') }}</h1></v-col
                            >
                            <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="convData.Province"
                                    :label="$tc('provincia', 1) + '*'"
                                    :items="Province"
                                    :rules="[$rules('RequiredObj')].flat()"
                                    class="pa-4"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    @change="updateCities()"
                                    :readonly="Province.length < 1"
                                    return-object
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="convData.City"
                                    :label="$t('citta') + '*'"
                                    :items="Citta"
                                    :rules="[$rules('RequiredObj')].flat()"
                                    item-text="name"
                                    return-object
                                    class="pa-4"
                                    outlined
                                    @change="updateCaps"
                                    :readonly="Citta.length < 1"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="convData.CAP"
                                    :label="$t('cap') + '*'"
                                    :items="Cap"
                                    :rules="[$rules('Required')].flat()"
                                    class="pa-4"
                                    outlined
                                    :readonly="Cap.length < 1"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <Opening :pedit="editing" v-model="lead.Opening" class="mb-2" />

                        <!--partita iva / categoria -->
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="lead.PIVA"
                                    :label="$t('partita-iva')"
                                    :rules="[$rules('maxLength250')].flat()"
                                    outlined
                                    :readonly="!editing"
                                    validate-on-blur
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="lead.Category"
                                    :items="selectCategory"
                                    :label="$t('categoria') + '*'"
                                    :rules="$rules('Required')"
                                    outlined
                                    hide-details
                                    :readonly="!editing"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="$permission($auth.utente.role.name, 'FullLeadTable')">
                                <v-text-field v-model="lead.csvCategory" label="csvcategoria" outlined hide-details :readonly="true"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>

                    <!--NOTE -->
                    <v-textarea
                        v-model="lead.Note"
                        class="pt-3"
                        hide-details
                        outlined
                        auto-grow
                        rows="2"
                        name="input-7-4"
                        :label="$t('note')"
                        value=""
                        :readonly="!editing"
                    ></v-textarea>
                </v-form>

                <div v-if="lead.Social && $permission($auth.utente.role.name, 'FullLeadTable')" class="mt-4">
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header> {{ $t('social') }} </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-text-field v-model="lead.Social.facebook" label="Facebook" class="mt-4" outlined :readonly="!editing"></v-text-field>
                                <v-text-field v-model="lead.Social.twitter" label="Twitter" class="mt-n4" outlined :readonly="!editing"></v-text-field>
                                <v-text-field v-model="lead.Social.instagram" label="Instagram" class="mt-n4" outlined :readonly="!editing"></v-text-field>
                                <v-text-field v-model="lead.Social.youtube" label="Youtube" class="mt-n4" outlined :readonly="!editing"></v-text-field>
                                <v-text-field v-model="lead.Social.linkedin" label="Linkedin" class="mt-n4" outlined :readonly="!editing"></v-text-field>
                                <v-text-field v-model="lead.Url" label="Website" class="mt-n4" outlined :readonly="!editing"></v-text-field>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <!-------<v-btn color="success" v-show="newu" @click="Create" :disabled="loading"> Crea </v-btn>  ----------------->
                <v-btn v-show="editing" color="success" @click="convertLead" class="text-center" :disabled="loading">
                    {{ $t('converti-lead') }}
                </v-btn>

                <v-btn color="success" v-show="editing && !newu" :disabled="loading" @click="Save"> {{ $t('salva') }} </v-btn>
                <v-btn color="error" v-show="editing && !newu" @click="Edit(false)" :disabled="loading"> {{ $t('annulla') }} </v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="showDeleteConfirmation" max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="mt-6, text-center mt-6"> {{ $t('sei-sicuro-di-voler-eliminare-il-lead-da-controllare') }} </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="showDeleteConfirmation = false"> {{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="Delete()"> {{ $t('elimina') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showAllOpenDialog" max-width="500">
            <v-card>
                <v-card-title class="headline justify-center">{{ $tc('orario', 2) }}</v-card-title>
                <v-card-text>
                    <Opening :popening="selected?.attributes.Opening" />
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn color="primary" @click="showAllOpenDialog = false">{{ $t('chiudi') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--DIALOG-->
        <v-row justify="center">
            <v-dialog v-if="leadExist" id="dialogLeadSimili" v-model="convDiag" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="convDiag = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-toolbar-title>{{ $t('esiste-gia-un-lead') }}</v-toolbar-title> <v-spacer></v-spacer>
                        <v-btn color="error" class="ml-6" @click="convertLead(false)"> {{ $t('converti-senza-unire') }} </v-btn>
                    </v-toolbar>
                    <div>
                        <v-row class="pa-4" justify="space-between">
                            <v-col cols="4">
                                <v-treeview :items="similarLeads" :active.sync="active" activatable color="warning" open-on-click transition>
                                    <template v-slot:prepend="{ item }">
                                        {{ item.attributes.Name }}

                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="item.attributes.child_leads?.data.length ? on : ''">
                                                    <v-btn
                                                        color="success"
                                                        class="ml-6"
                                                        :disabled="item.attributes.child_leads?.data.length ? true : false"
                                                        @click=";(mergeLead = item.id), (openmerge = true)"
                                                    >
                                                        {{ $t('unisci') }}
                                                    </v-btn>
                                                </span>
                                            </template>
                                            <span>{{ $t('il-lead-ha-un-derivato-unificare-i-lead') }} </span>
                                        </v-tooltip>
                                    </template>
                                </v-treeview>
                            </v-col>

                            <v-divider vertical></v-divider>

                            <v-col class="text-center">
                                <v-scroll-y-transition mode="out-in">
                                    <div v-if="!selected" class="text-h6 grey--text text--lighten-1 font-weight-light" style="align-self: center">
                                        {{ $t('seleziona-il-lead-per-vedere-le-informazioni') }}
                                    </div>
                                    <v-card v-else :key="selected.id" class="mx-auto" flat
                                        ><cpLead
                                            v-if="leadExist"
                                            :pid="selected.id"
                                            :pbuttons="{ delete: false, backArrow: false, edit: false, merge: false, addTo: false, navigate: false }"
                                            :title="false"
                                            v-on:error="errorDetails"
                                        ></cpLead>
                                        <span v-show="erroreDetail">{{ erroreDetail }}</span>
                                    </v-card>
                                </v-scroll-y-transition>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- dialog sicuro di voler cancellare -->
                </v-card>
            </v-dialog>

            <!--MERGE DIALOG-->

            <Merge
                v-model="openmerge"
                :pid="[mergeLead, id]"
                :types="['leads', 'leadToChecks']"
                :details="[null, { p: convData.Province, ci: convData.City, ca: convData.CAP }, null]"
                :noUnion="true"
                @convertLead="convertLead(false)"
            >
            </Merge>
        </v-row>
    </v-container>
</template>

<script>
import Opening from '@/components/Opening.vue'
import Merge from '@/components/merge.vue'
import cpLead from '@/components/cpLead.vue'
import loading from '@/components/loading.vue'

export default {
    name: 'LeadToCheck',
    components: {
        Opening,
        Merge,
        cpLead,
        loading
    },
    data() {
        return {
            mergeLead: null,

            erroreDetail: null,
            id: null,
            lead: {
                Name: null,
                Phone: null,
                Email: null,
                Address: null,
                Province: null,
                City: null,
                CAP: null,
                Opening: null,
                Social: { facebook: '', instagram: '', youtube: '', linkedin: '', twitter: '', tiktok: '' },
                Longitude: null,
                Latitude: null,
                Category: null,
                Url: null,
                PIVA: null,
                CF: null
            },
            loading: false,
            editing: null,
            newu: null,
            valid: true,
            convDiag: false,
            Province: [],
            Citta: [],
            Cap: [],
            convData: { Province: {}, City: {}, CAP: '' },
            showDeleteConfirmation: false,
            leadExist: false,
            similarLeads: [],
            active: [],
            showAllOpenDialog: false,
            openmerge: false,
            selectCategory: []
        }
    },

    props: {
        pedit: { type: Boolean, default: false },
        pnew: { type: Boolean, default: false }
    },

    computed: {
        selected() {
            if (!this.active.length) return null

            const id = this.active[0]
            return this.similarLeads.find((x) => x.id == id)
        }
    },

    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'LeadToCheckPage')) {
            this.$router.push('/')
            return
        }
        this.newu = this.pnew
        if (this.newu) this.editing = true

        await this.getCategoriesFromApi()

        if (this.$route.params.id) {
            let doc = await this.$http
                .get(`leadToChecks/${this.$route.params.id}`, {
                    populate: [['Social', 'Opening', 'Opening.mon', 'Opening.tue', 'Opening.wed', 'Opening.thu', 'Opening.fri', 'Opening.sat', 'Opening.sun']]
                })
                .catch((err) => {
                    console.log('errore', err)
                })
            if (!doc) {
                this.$router.push('/')
                return
            }
            if (!doc.data.attributes.Social) {
                doc.data.attributes.Social = this.lead.Social
            }
            doc.data.attributes.Opening = this.$opening2UI(doc.data.attributes.Opening)
            this.id = doc.data.id
            this.lead = {
                Name: doc.data.attributes.Name,
                Phone: doc.data.attributes.Phone,
                Email: doc.data.attributes.Email,

                Address: doc.data.attributes.Address,
                Province: doc.data.attributes.Province,
                City: doc.data.attributes.City,
                CAP: doc.data.attributes.CAP,
                Opening: doc.data.attributes.Opening,
                Social: doc.data.attributes.Social,
                Longitude: doc.data.attributes.Longitude,
                Latitude: doc.data.attributes.Latitude,
                Category: doc.data.attributes.Category,
                Url: doc.data.attributes.Url,
                Source: doc.data.attributes.Source,
                PIVA: doc.data.attributes.PIVA,
                CF: doc.data.attributes.CF,
                csvCategory: doc.data.attributes.csvCategory
            }
            if (this.pedit) {
                this.Edit(true)
            }
        }
    },

    methods: {
        errorDetails(err) {
            console.log('err', err)
        },
        merge() {
            this.openmerge = true
        },
        openingChange(val) {
            this.lead.Opening = val
        },

        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        async getCategoriesFromApi() {
            const p = await this.$http.get(`categories`, {
                'pagination[pageSize]': 200,
                'sort': 'name'
            })
            if (p.data.length) {
                this.selectCategory = p.data.map((x) => {
                    return x.attributes.name
                })
            }
        },

        async Edit(stato) {
            if (stato) {
                this.leadOriginal = JSON.parse(JSON.stringify(this.lead))
                await this.checkConvert()
            } else {
                this.lead = JSON.parse(JSON.stringify(this.leadOriginal))
            }

            this.editing = stato
        },

        async Save() {
            let status = false
            if (this.loading) return status
            if (!this.$refs.form.validate()) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-nel-form-verificare-i-campi'),
                    type: 'error'
                })
                return status
            }
            try {
                this.loading = true

                const lead = await this.$http.put(`leadToChecks/${this.id}`, {
                    data: { ...this.lead, Opening: this.$opening2DB(this.lead.Opening) }
                })

                //success
                status = true
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('salvataggio-avvenuto-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('errore-nel-salvataggio'),
                    type: 'error'
                })
            } finally {
                this.editing = false

                this.loading = false
            }
            return status
        },

        async checkConvert() {
            const p = await this.$http.get(`provinces`, { 'pagination[pageSize]': 200 })
            this.Province = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.convData = { Province: {}, City: { id: null, name: null, cap: [] }, CAP: '' }
            this.convData.Province = this.Province.find((x) => {
                return x.name.toLowerCase() == this.lead.Province?.toLowerCase()
            }) || { id: null, name: null }
            if (this.convData.Province.id) {
                await this.getCities(this.convData.Province)
                this.convData.City = this.Citta.find((x) => {
                    return x.name.toLowerCase() == this.lead.City?.toLowerCase()
                }) || { id: null, name: null, cap: [] }
                if (this.convData.City.id) {
                    await this.getCaps()
                    this.convData.CAP =
                        this.Cap.find((x) => {
                            return x.toLowerCase() == this.lead.CAP?.toLowerCase()
                        }) || ''
                }
            }
        },
        async updateCities() {
            if (!this.convData.Province?.id) {
                this.Citta = []
                return
            }
            this.convData.City = { id: null, name: null, cap: [] }
            await this.getCities(this.convData.Province)
            this.getCaps()
        },
        updateCaps() {
            if (!this.convData.City?.id) {
                this.Cap = []
                return
            }
            this.convData.CAP = ''
            this.getCaps()
        },
        async getCities(prov) {
            this.Citta = []
            //this.ruletmp.cities = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`cities`, {
                    'populate': ['Province'],
                    'filters': { Province: { id: { $eq: prov.id } } },
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'Name'
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            this.Citta = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name, cap: x.attributes.Cap }
            })
        },
        getCaps() {
            this.Cap = this.convData.City.cap || []
            if (this.Cap.length == 1) {
                this.convData.CAP = this.Cap[0]
            }
        },

        async convertLead(check = true) {
            if (!this.$refs.convform.validate()) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('compilare-i-campi-richiesti-prima-di-convertire-il-lead'),
                    type: 'error'
                })
                return
            }

            try {
                await this.Save()
                this.loading = true

                if (check) {
                    const existingLeads = await this.$http.get(`leads`, {
                        filters: {
                            Name: this.lead.Name,
                            City: { id: { $eq: this.convData.City.id } },
                            disabled: { $eq: false },
                            child_leads: { id: { $null: true } }
                        },
                        populate: ['Province', 'City', 'child_leads']
                    })

                    if (existingLeads.data.length > 1) {
                        this.similarLeads = existingLeads.data
                        this.convDiag = true
                        this.leadExist = true
                        return
                    } else if (existingLeads.data.length == 1) {
                        this.mergeLead = existingLeads.data[0].id
                        this.openmerge = true
                        return
                    }
                }

                const lead = await this.$http.post(`leads`, {
                    data: {
                        ...this.lead,
                        Opening: this.$opening2DB(this.lead.Opening),
                        Province: this.convData.Province.id,
                        City: this.convData.City.id,
                        CAP: this.convData.CAP,
                        Source: `Utente ${this.$auth.utente.id}`
                    }
                })
                await this.$http.put(`leadToChecks/${this.id}`, {
                    data: { disabled: true }
                })

                //success
                this.$notify({
                    group: 'notifica',
                    title: this.$t('conversione'),
                    text: this.$t('conversione-avvenuta-con-successo'),
                    type: 'success'
                })
                this.$router.push('/leadsToCheck')
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('conversione'),
                    text: this.$t('errore-nella-conversione'),
                    type: 'error'
                })
            } finally {
                this.loading = false
            }
        },

        async Delete() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteLeadToCheck')) {
                return
            }
            try {
                // Effettua la chiamata HTTP per eliminare il lead
                await this.$http.put(`leadToChecks/${this.id}`, {
                    data: { disabled: true }
                })

                // Chiudi il dialogo di conferma ed esegui altre azioni
                this.showDeleteConfirmation = false
                this.$router.push('/leadsToCheck')
            } catch (error) {
                // Gestisci l'errore durante l'eliminazione del lead
                console.error(error)

                // Mostra una notifica di errore
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-del-leads-da-controllare'),
                    type: 'error'
                })
                this.showDeleteConfirmation = false
            }
        }
    }
}
</script>
<style>
.v-application .title {
    font-size: 1.25rem !important;
    font-weight: 500;
    letter-spacing: 0.0125em !important;
    width: 700px;
}
.v-list-item__title,
.v-list-item__subtitle {
    flex: initial !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.v-list-item__subtitle,
.v-list-item__title {
    flex: initial !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.v-card__subtitle,
.v-card__text {
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
}
.v-application .text-right {
    display: block ruby;

    margin-bottom: -20px;
}
.dialog-drag {
    width: 1500px;
}
</style>
