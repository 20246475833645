import Vue from 'vue'
import VueRouter from 'vue-router'

import { auth, nextFactory } from './middlewares'
import Leads from '../views/Leads.vue'
import Lead from '../views/Lead.vue'
import LeadsToCheck from '../views/LeadsToCheck.vue'
import LeadToCheck from '../views/LeadToCheck.vue'
import SignIn from '../views/SignIn.vue'
import Users from '../views/Users.vue'
import User from '../views/User.vue'
import Zones from '../views/Zones.vue'
import Zone from '../views/Zone.vue'
import Giro from '../views/Giro.vue'
import Mappa from '../views/Mappa.vue'
import Marketing from '../views/Marketing.vue'
import RepProvince from '../views/RepProvince.vue'
import Admin from '../views/Admin.vue'
import ClearDB from '../views/ClearDB.vue'
import Logs from '../views/Logs.vue'
import updateCoordinates from '../views/updateCoordinates.vue'
import updateCoordinate from '../views/updateCoordinate.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/giro'
    },
    {
        path: '/leads',
        name: 'Leads',
        component: Leads,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/lead',
        name: 'Lead',
        component: Lead,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/lead/new',
        name: 'Lead new',
        component: Lead,
        meta: {
            middlewares: [auth]
        },
        props: { pnew: true }
    },
    {
        path: '/lead/:id',
        name: 'Lead id',
        component: Lead,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/lead/:id/edit',
        name: 'Lead edit',
        component: Lead,
        meta: {
            middlewares: [auth]
        },
        props: { pedit: true }
    },
    {
        path: '/leadsToCheck',
        name: 'LeadsToCkeck',
        component: LeadsToCheck,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/leadToCheck/:id',
        name: 'LeadToCkeck',
        component: LeadToCheck,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/leadToCheck/:id/edit',
        name: 'LeadToCkeck edit',
        component: LeadToCheck,
        meta: {
            middlewares: [auth]
        },
        props: { pedit: true }
    },
    {
        path: '/signin',
        name: 'SignIn',
        component: SignIn,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/user',
        name: 'User',
        component: User,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/user/new',
        name: 'User new',
        component: User,
        meta: {
            middlewares: [auth]
        },
        props: { pnew: true }
    },
    {
        path: '/user/:id',
        name: 'User id',
        component: User,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/user/:id/edit',
        name: 'User edit',
        component: User,
        meta: {
            middlewares: [auth]
        },
        props: { pedit: true }
    },
    {
        path: '/zones',
        name: 'Zones',
        component: Zones,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/zone',
        name: 'Zone',
        component: Zone,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/zone/new',
        name: 'Zone new',
        component: Zone,
        meta: {
            middlewares: [auth]
        },
        props: { pnew: true }
    },
    {
        path: '/zone/:id',
        name: 'Zone id',
        component: Zone,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/zone/:id/edit',
        name: 'Zone edit',
        component: Zone,
        meta: {
            middlewares: [auth]
        },
        props: { pedit: true }
    },
    {
        path: '/map',
        name: 'Map',
        component: Mappa,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/giro',
        name: 'Giro',
        component: Giro,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/resetpassword',
        name: 'Resetpassword',
        component: SignIn,
        props: { ptype: 'new' }
    },
    {
        path: '/admin/marketing',
        name: 'Marketing',
        component: Marketing,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/admin/repprovince',
        name: 'RepProvince',
        component: RepProvince,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/admin/cleardb',
        name: 'ClearDB',
        component: ClearDB,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/admin/actionlog',
        name: 'Logs',
        component: Logs,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/admin/updateCoordinates',
        name: 'updateCoordinates',
        component: updateCoordinates,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/updateCoordinate/:id/edit',
        name: 'updateCoordinate',
        component: updateCoordinate,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const ctx = Vue.prototype

router.beforeEach((to, from, next) => {
    if (to.meta.middlewares) {
        const middlewares = Array.isArray(to.meta.middlewares) ? to.meta.middlewares : [to.meta.middlewares]
        const context = { ctx, from, to, next, router }
        const nextMiddleware = nextFactory(context, middlewares, 1)
        const auths = to.meta.authorize ? to.meta.authorize : []
        return middlewares[0](auths, { ...context, next: nextMiddleware })
    }

    return next()
})

export default router
