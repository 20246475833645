<template>
    <v-container fluid>
        <v-row class="text-center">
            <v-col cols="12">
                <v-row :class="{ 'mt-10': !$vuetify.breakpoint.mdAndUp, 'mt-10 pt-10': $vuetify.breakpoint.mdAndUp }">
                    <!-------------- import lead  ----------------->

                    <v-col cols="4" sm="4" class="d-flex justify-center">
                        <v-card flat v-if="$permission($auth.utente.role.name, 'importcsv')">
                            <div class="text-center" @click="importLeads">
                                <v-btn rounded color="orange" class="d-none d-md-flex" large
                                    ><v-icon class="pa-2">download</v-icon> {{ $t('importa', { page: this.$tc('lead', 2) }) }}
                                </v-btn>
                                <v-icon color="orange" class="pointer d-flex d-md-none mt-5" x-large>download</v-icon>
                            </div>
                        </v-card>
                    </v-col>
                    <!-------------- title ----------------->
                    <v-col cols="4" sm="4" class="d-flex justify-center">
                        <h1>
                            {{ $t('sezione', { page: this.$tc('lead', 2) }) }}
                        </h1>
                    </v-col>
                    <!-------------- delete all lead  

                        <v-col cols="6" sm="4" v-if="$auth.utente.role.name == 'Super'">
                            <div class="text-center d-flex">
                                <v-btn large rounded color="error" @click="deleteAll"> Cancella tutti </v-btn>
                            </div>
                        </v-col>
                        ----------------->

                    <!-------------- leads da controllare ----------------->

                    <v-col
                        :cols="$permission($auth.utente.role.name, 'importcsv') ? '4' : '4'"
                        class="d-flex justify-center"
                        v-if="$permission($auth.utente.role.name, 'LeadToCheckPage')"
                    >
                        <router-link to="/leadsToCheck" class="text-decoration-none">
                            <v-btn large rounded color="primary" class="d-none d-md-flex">
                                <v-icon class="pa-2">verified</v-icon> {{ $t('l-da-controllare', { page: this.$tc('lead', 2) }) }}
                            </v-btn>
                            <v-icon color="primary" class="d-flex d-md-none mt-5" x-large>verified</v-icon>
                        </router-link>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <!-------------- new agente----------------->

                    <v-col cols="2" sm="3" class="d-flex justify-center">
                        <router-link to="/lead/new" class="text-decoration-none">
                            <v-btn rounded large color="success " class="d-none d-md-flex">
                                <v-icon class="pa-2">add_circle</v-icon> {{ $t('crea-', { page: $tc('lead', 1) }) }}
                            </v-btn>
                            <v-icon color="success" class="d-flex d-md-none" x-large>add_circle</v-icon>
                        </router-link>
                    </v-col>
                    <!-------------- search DESKTOP----------------->

                    <v-col cols="8" sm="6" class="d-flex justify-center mb-4">
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            :label="$t('ricerca-nome')"
                            single-line
                            hide-details
                            class=""
                            clearable
                        ></v-text-field>
                    </v-col>

                    <!-------------- filter ----------------->

                    <v-col cols="2" sm="3" class="d-flex justify-center">
                        <div @click="modalFilter = !modalFilter">
                            <v-btn large rounded color="success" class="d-none d-md-flex">
                                <v-icon class="pa-2">filter_alt</v-icon> {{ $t('imposta-filtri') }}
                            </v-btn>
                            <v-icon color="success" class="d-flex d-md-none" x-large>filter_alt</v-icon>
                        </div>

                        <div class="text-center">
                            <v-bottom-sheet v-model="modalFilter">
                                <v-sheet class="text-center scrollable-sheet" height="auto">
                                    <!-------------- reset filter ----------------->
                                    <v-container cols="12">
                                        <v-row class="d-flex justify-center">
                                            <!-- Close button -->
                                            <v-col cols="3" class="d-flex justify-center" @click="modalFilter = !modalFilter">
                                                <v-btn class="mt-n8 d-none d-sm-flex" color="error">{{ $t('chiudi') }}</v-btn>
                                                <v-btn color="error" class="d-flex d-sm-none icon"><v-icon> cancel </v-icon></v-btn>
                                            </v-col>

                                            <!-- Reset filters button -->
                                            <v-col cols="6">
                                                <v-btn class="mt-n8" color="warning" @click="clearFilters">{{ $t('reset-filtri') }}</v-btn>

                                                <h1 v-if="$vuetify.breakpoint.smAndUp" class="mt-4">
                                                    {{ $tc('filtro', 2) }}
                                                </h1>

                                                <h3 v-else class="ma-0">
                                                    {{ $tc('filtro', 2) }}
                                                </h3>
                                            </v-col>

                                            <!-- Apply button -->
                                            <v-col cols="3" class="d-flex justify-center" @click="setFilter()">
                                                <v-btn class="mt-n8 d-none d-sm-flex" color="success">{{ $t('applica') }}</v-btn>
                                                <v-btn color="success" class="d-flex d-sm-none icon"> <v-icon> search </v-icon></v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <div :class="{ 'pr-6 pl-6 scrollable-content': !$vuetify.breakpoint.smAndUp, 'pa-6': $vuetify.breakpoint.smAndUp }">
                                        <v-row>
                                            <v-col cols="12 " sm="4">
                                                <v-autocomplete
                                                    :label="$tc('provincia', 1)"
                                                    item-text="name"
                                                    :search-input.sync="searchProv"
                                                    cache-items
                                                    multiple
                                                    hide-no-data
                                                    v-model="selectProv"
                                                    :loading="loadingProv"
                                                    :items="Province"
                                                    return-object
                                                    solo-inverted
                                                    flat
                                                    chips
                                                    deletable-chips
                                                    clearable
                                                ></v-autocomplete
                                            ></v-col>
                                            <v-col cols="12 " sm="4">
                                                <v-autocomplete
                                                    :label="$tc('citta', 1)"
                                                    item-text="name"
                                                    item-value="id"
                                                    :search-input.sync="searchCity"
                                                    cache-items
                                                    multiple
                                                    hide-no-data
                                                    v-model="selectCity"
                                                    :loading="loadingCity"
                                                    :items="Cities"
                                                    return-object
                                                    solo-inverted
                                                    flat
                                                    chips
                                                    deletable-chips
                                                    clearable
                                                >
                                                    <template v-slot:item="data">
                                                        <template>
                                                            <v-list-item-content>
                                                                <v-list-item-title> {{ data.item.name }} </v-list-item-title>
                                                                <v-list-item-subtitle>{{ data.item.prov }}</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete></v-col
                                            >
                                            <v-col cols="12 " sm="4"
                                                ><span v-for="(c, i) in selectCaps" :key="i">
                                                    <v-chip close @click:close="removeCap(i)"> {{ c }} </v-chip>
                                                </span>
                                                <div class="d-flex flex-row">
                                                    <v-text-field v-model="cap" :label="$tc('cap-insert', 1)" solo-inverted flat type="number"> </v-text-field>
                                                    <v-icon class="ml-7 mt-n6" @click="addCap">add_circle</v-icon>
                                                </div></v-col
                                            >
                                            <v-col cols="12 " sm="6">
                                                <v-autocomplete
                                                    :label="$tc('assegnato-ad-agente')"
                                                    item-text="name"
                                                    :search-input.sync="searchAgent"
                                                    cache-items
                                                    hide-no-data
                                                    v-model="selectAgent"
                                                    :loading="loadingAgent"
                                                    :items="Agents"
                                                    return-object
                                                    solo-inverted
                                                    flat
                                                    chips
                                                    deletable-chips
                                                    clearable
                                                >
                                                </v-autocomplete
                                            ></v-col>
                                            <v-col cols="12 " sm="6">
                                                <v-autocomplete
                                                    :label="$tc('tag', 1)"
                                                    item-text="label"
                                                    item-value="id"
                                                    :search-input.sync="searchTag"
                                                    cache-items
                                                    hide-no-data
                                                    v-model="selectTag"
                                                    :loading="loadingTag"
                                                    :items="tags"
                                                    multiple
                                                    solo-inverted
                                                    flat
                                                    chips
                                                    deletable-chips
                                                    clearable
                                                >
                                                </v-autocomplete
                                            ></v-col>
                                            <v-col cols="12 " sm="6">
                                                <v-autocomplete
                                                    :label="$tc('giorno', 1)"
                                                    hide-no-data
                                                    v-model="selectDay"
                                                    :loading="loadingDay"
                                                    :items="$getDayName()"
                                                    solo-inverted
                                                    flat
                                                    chips
                                                    deletable-chips
                                                    clearable
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12 " sm="6" v-if="$permission($auth.utente.role.name, 'FullLeadTable')">
                                                <v-autocomplete
                                                    :label="$tc('categoria', 1)"
                                                    item-text="name"
                                                    item-value="value"
                                                    multiple
                                                    hide-no-data
                                                    v-model="selectCat"
                                                    :items="categorie"
                                                    solo-inverted
                                                    flat
                                                    chips
                                                    deletable-chips
                                                    clearable
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-sheet>
                            </v-bottom-sheet>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!------------- GRIGLIE ----------------->
        <v-card class="marginScroll">
            <v-data-table
                :headers="headers"
                :items="leads"
                :options.sync="options"
                :server-items-length="totalLeads"
                :loading="loading"
                :search="search"
                class="pt-3"
                :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100], showFirstLastPage: true }"
                :item-class="itemRowBackground"
                show-select
                v-model="selectedLeads"
                @update:options="updateUrl"
                :mobile-breakpoint="1270"
            >
                <!-- pulsante azioni -->

                <template v-slot:top>
                    <v-menu transition="slide-y-transition" bottom v-if="selectedLeads.length" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="ml-6 mt-n2 mb-4" v-bind="attrs" v-on="on"> {{ $tc('azione', 2) }} </v-btn>
                        </template>
                        <v-list class="text-uppercase">
                            <v-list-item @click="openNewTag()">
                                <v-list-item-title class="ma-5 pointer">
                                    <v-icon color="black" class="mb-1" left> bookmark </v-icon> {{ $t('aggiungi-', { to: $tc('tag', 1) }) }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="addTo()">
                                <v-list-item-title class="ma-5 pointer">
                                    <v-icon color="black" class="mb-1" left> add_location_alt </v-icon>
                                    {{ $t('aggiungi-a-', { to: $tc('giro', 1) }) }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="$permission($auth.utente.role.name, 'FullLeadTable') && (selectedLeads.length == 2 || selectedLeads.length == 3)"
                                @click="merge()"
                            >
                                <v-list-item-title class="ma-5 pointer"
                                    ><v-icon color="black" class="mb-1" left> merge </v-icon> {{ $t('unifica') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="showDeleteDialogSelect" v-if="$permission($auth.utente.role.name, 'deleteLead')">
                                <v-list-item-title class="ma-5 pointer"
                                    ><v-icon color="black" class="mb-1" left> delete </v-icon> {{ $t('elimina') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

                <!-- Aggiunta colonna "azione" -->

                <template #[`item.id`]="{ value }">
                    <v-container class="d-flex flex-row pl-0 action-mobile">
                        <router-link :to="`/lead/${value}/edit`">
                            <v-icon :class="{ 'pr-3': !$vuetify.breakpoint.smAndUp, 'pt-1': $vuetify.breakpoint.smAndUp }" class="pt-1 d-inline-flex"
                                >edit</v-icon
                            >
                        </router-link>
                        <v-icon class="pr-3 d-inline-flex d-sm-none" @click="addTo(value)">add_location_alt</v-icon>

                        <router-link :to="`/lead/${value}`">
                            <v-icon class="pa-1 d-inline-flex">visibility</v-icon>
                        </router-link>
                        <a :href="getMapUrl(value)" target="_blank"> <v-icon class="pl-3 pa-1 d-flex d-sm-none">near_me</v-icon></a>

                        <!--pulsante espandi-->
                        <div class="d-none d-sm-flex">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on">expand_more</v-icon>
                                </template>
                                <v-list class="text-uppercase">
                                    <v-list-item class="d-flex flex-row" @click="addTo(value)">
                                        <v-btn text color="black">
                                            <v-icon class="pa-3 d-inline-flex">add_location_alt</v-icon>{{ $t('aggiungi-a-', { to: $tc('giro', 1) }) }}</v-btn
                                        >
                                    </v-list-item>

                                    <v-list-item class="d-flex flex-row" :href="getMapUrl(value)" target="_blank">
                                        <v-btn text color="black">
                                            <v-icon class="pa-3 d-inline-block" color="black">near_me</v-icon>
                                            {{ $t('google-maps') }}
                                        </v-btn>
                                    </v-list-item>
                                    <v-list-item
                                        class="d-flex flex-row"
                                        v-if="$permission($auth.utente.role.name, 'deleteLead')"
                                        @click="showDeleteDialog(value)"
                                    >
                                        <v-btn text color="black"> <v-icon class="pa-3 d-inline-flex">cancel</v-icon>{{ $t('elimina') }}</v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </v-container>
                </template>

                <!-- Aggiunta colonna "ORARIO" -->
                <template #[`item.attributes.Opening`]="{ value }">
                    <v-btn :disabled="!$hasOpening(value)" color="primary" @click="showAllOpen(value)">
                        {{ $tc('orario', 1) }}
                    </v-btn>
                </template>

                <template #[`item.attributes.Province`]="{ value }">
                    {{ value?.data?.attributes.Name || '' }}
                </template>
                <template #[`item.attributes.City`]="{ value }">
                    {{ value?.data?.attributes.Name || '' }}
                </template>
                <template #[`item.attributes.zones.data`]="{ value }">
                    <span>{{ getAgents(value) }}</span>
                </template>
                <template #[`item.attributes.edit_agents.data`]="{ value }">
                    <span>{{ value.map((x) => x.attributes.Name).join(', ') }}</span>
                </template>
            </v-data-table>
            <v-dialog v-model="showAllOpenDialog" max-width="500">
                <v-card>
                    <v-card-title class="headline justify-center">{{ $tc('orario', 2) }}</v-card-title>
                    <v-card-text>
                        <Opening v-model="selectedOpening" />
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="error" @click="showAllOpenDialog = false">{{ $tc('chiudi') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Aggiunta tag  -->
            <v-bottom-sheet v-model="showTag" inset>
                <v-sheet class="text-center" height="250px">
                    <div class="d-flex justify-center">
                        <v-col cols="3">
                            <v-btn class="mt-n8" color="error" @click="showTag = !showTag"> {{ $tc('chiudi') }} </v-btn>
                        </v-col>
                        <v-col cols="3">
                            <v-btn @click="salvaTag()" class="mt-n8" color="success">{{ $t('applica') }}</v-btn>
                        </v-col>
                    </div>
                    <v-autocomplete
                        chips
                        deletable-chips
                        multiple
                        outlined
                        :label="$tc('tag', 1)"
                        v-model="newTags"
                        :items="tags"
                        item-text="label"
                        item-value="id"
                        :class="{ 'pa-12': $vuetify.breakpoint.smAndUp }"
                        :search-input.sync="searchNewTag"
                        :loading="loadingTag"
                    >
                        <template v-slot:no-data>
                            <v-list-item ripple @click="addTag()" v-show="searchNewTag?.length > 2">
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('aggiungi-', { to: $tc('tag', 1) }) }}: {{ searchNewTag }} </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                        </template>
                    </v-autocomplete>
                </v-sheet>
            </v-bottom-sheet>
            <!-- dialog sicuro di voler cancellare -->

            <v-dialog v-model="showDeleteConfirmation" max-width="500">
                <v-card outlined>
                    <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                    <v-card-text class="mt-6, text-center mt-6"> {{ deleteDialogMsg }}</v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn text @click="cancelDelete">{{ $t('annulla') }}</v-btn>
                        <v-spacer></v-spacer>

                        <v-btn color="red" text @click="deleteL(deleteDialogCall)">{{ $t('elimina') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
        <addToPlan v-model="addto"></addToPlan>

        <Merge v-model="openmerge" :pid="mergeLeads" :types="['leads', 'leads', 'leads']" :details="[null, null, null]"></Merge>
    </v-container>
</template>

<script>
import Opening from '@/components/Opening.vue'
import addToPlan from '@/components/addToPlan.vue'
import Merge from '@/components/merge.vue'
import { id } from 'vuetify/lib/locale'

export default {
    name: 'Leads',
    components: {
        Opening,
        addToPlan,
        Merge
    },
    data() {
        return {
            leads: [],
            totalLeads: 0,
            loading: true,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: null,
                mustSort: null
            },
            search: '',
            timeoutQuery: null,
            showAllOpenDialog: false,
            selectedOpening: [],
            showDeleteConfirmation: false,
            deleteDialogMsg: '',
            deleteDialogCall: null,
            leadToDelete: null,
            modalFilter: false,

            headers: [
                {
                    text: this.$tc('azione', 1),
                    align: 'start',
                    sortable: false,
                    value: 'id',
                    field: 'id'
                },
                { text: this.$tc('nome-azienda', 1), value: 'attributes.Name', field: 'Name' },
                {
                    text: this.$tc('indirizzo', 1),
                    value: 'attributes.Address',
                    field: 'Address'
                },
                {
                    text: this.$tc('citta', 1),
                    value: 'attributes.City',
                    field: 'City.Name'
                },
                {
                    text: this.$tc('cap', 1),
                    value: 'attributes.CAP',
                    field: 'CAP'
                },
                {
                    text: this.$tc('provincia', 1),
                    value: 'attributes.Province',
                    field: 'Province.Name'
                },
                {
                    text: this.$tc('telefono', 1),
                    value: 'attributes.Phone',
                    field: 'Phone'
                },
                {
                    text: this.$tc('orario', 1),
                    value: 'attributes.Opening',
                    sortable: false,
                    field: 'Opening'
                }
            ],
            searchProv: null,
            selectProv: [],
            loadingProv: false,
            Province: [],
            searchCity: null,
            selectCity: [],
            loadingCity: false,
            Cities: [],
            cap: null,
            selectCaps: [],
            userZones: [],
            selectedLeads: [],
            changinurl: false,
            searchAgent: null,
            selectAgent: null,
            loadingAgent: false,
            Agents: [],
            addto: [],
            searchTag: null,
            selectTag: null,
            loadingTag: false,
            tags: [],
            selectDay: null,
            loadingDay: false,

            mergeLeads: [],
            openmerge: false,
            showTag: false,
            newTags: [],
            searchNewTag: null,
            categorie: [],
            selectCat: []
        }
    },
    watch: {
        search: {
            handler() {
                if (!this.search || this.search.length == 0) {
                    this.setFilter()
                    return
                }
                if (this.search?.length < 3) {
                    return
                }
                if (this.timeoutQuery) {
                    clearTimeout(this.timeoutQuery)
                }
                this.timeoutQuery = setTimeout(() => {
                    this.setFilter()
                }, 300)
            }
        },

        searchProv(val) {
            if (this.$permission(this.$auth.utente.role.name, 'filterProvince')) {
                val && this.getProvince(val)
            } else {
                val
            }
        },
        searchCity(val) {
            if (this.$permission(this.$auth.utente.role.name, 'filterCity')) {
                val && this.getCities(val)
            } else {
                val
            }
        },
        searchAgent(val) {
            if (this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                val && this.getAgentsList(val)
            } else {
                null
            }
        },
        searchTag(val) {
            val && this.getTagsList(val)
        }
    },
    async created() {
        this.changinurl = true
        if (this.$route.query.page) {
            this.options.page = parseInt(this.$route.query.page)
        }
        if (this.$route.query.items) {
            this.options.itemsPerPage = parseInt(this.$route.query.items)
        }
        if (this.$route.query.sortBy) {
            this.options.sortBy[0] = this.$route.query.sortBy
            if (this.$route.query.sortDesc) {
                this.options.sortDesc[0] = this.$route.query.sortDesc
            }
        }
        if (this.$route.query.cap) {
            this.selectCaps = this.$route.query.cap.split(',')
        }
        if (this.$route.query.cat) {
            this.selectCat = this.$route.query.cat.split(',').map((x) => {
                if (x == 'empty') {
                    return ''
                } else {
                    return x
                }
            })
        }
        if (this.$route.query.tag) {
            await this.getTagsFromApiId(this.$route.query.tag)
        }
        if (this.$route.query.day) {
            this.selectDay = this.$route.query.day
        }
        if (this.$route.query.search) {
            this.search = decodeURIComponent(this.$route.query.search)
        }
        if (this.$route.query.provincia) {
            await this.getProvinceFromApiId(this.$route.query.provincia)
        }
        if (this.$route.query.citta) {
            await this.getCitiesFromApiId(this.$route.query.citta)
        }

        if (this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
            this.headers = [
                ...this.headers,
                {
                    text: this.$t('assegnato-ad-agente'),
                    value: 'attributes.zones.data',
                    sortable: false,
                    field: 'agents'
                },
                {
                    text: this.$t('versione-dell-agente'),
                    value: 'attributes.edit_agents.data',
                    sortable: false,
                    field: 'edit_agents'
                }
            ]
            if (this.$route.query.agente) {
                await this.getAgentsFromApiId(this.$route.query.agente)
            }
            await this.getProvinceFromApi('')
        } else {
            //recupero zone assegnate
            let prov = new Set()
            let cit = new Set()
            const p = await this.$http.get(`users/me`, { populate: ['agent', 'agent.zones'] })
            if (p.agent.zones) {
                p.agent.zones.forEach((z) => {
                    this.userZones.push(z.id)
                    z.Rules.forEach((r) => {
                        prov.add(r.prov)
                        r.cities.forEach((c) => {
                            cit.add({ id: c.id, name: c.name, prov: r.prov.name })
                        })
                    })
                })
            }
            this.Province = [...prov]
            this.Cities = [...cit]
        }

        await this.getCategoriesFromApi()
        await this.getCategorieFromZones()
        await this.getDataFromApi()

        this.changinurl = false
    },
    methods: {
        async getCategorieFromZones() {
            try {
                //recupera le zone dell'agente con le categorie
                const response = await this.$http.get(`agents/${this.$auth.utente.agent.id}`, {
                    populate: ['zones', 'zones.Category']
                })

                if (response && response.data && response.data.attributes && response.data.attributes.zones) {
                    const zones = response.data.attributes.zones.data
                    let categorieTrovate = new Set()
                    zones.forEach((zone) => {
                        // Controlla se la zona ha una categoria associata
                        if (zone.attributes && zone.attributes.selectedCategories) {
                            if (Array.isArray(zone.attributes.selectedCategories)) {
                                zone.attributes.selectedCategories.forEach((cat) => {
                                    // Assicurati che ogni categoria sia una stringa
                                    if (typeof cat === 'string') {
                                        categorieTrovate.add(cat)
                                    } else if (cat && typeof cat === 'object' && cat.value) {
                                        categorieTrovate.add(cat.value)
                                    }
                                })
                            } else {
                                // Se è una singola categoria, aggiungila direttamente
                                categorieTrovate.add(zone.attributes.selectedCategories)
                            }
                        }
                    })

                    // Se ci sono categorie trovate e non ci sono categorie già impostate dall'URL
                    if (categorieTrovate.size > 0 && !this.$route.query.cat) {
                        // Converti il Set in un array semplice di stringhe
                        const categorieArray = [...categorieTrovate]

                        // Assegna direttamente l'array di stringhe a selectCat
                        this.selectCat = categorieArray

                        // Dopo aver impostato le categorie, aggiorna l'URL
                        if (!this.changinurl) {
                            this.setFilter()
                        }
                    }
                }
            } catch (error) {
                console.console.error('Errore nel recupero delle categorie dalle zone:', error)
                ;('gg')
            }
        },
        clearFilters() {
            // Reset all filter
            this.searchProv = null
            this.selectProv = []
            this.searchCity = null
            this.selectCity = []
            this.cap = null
            this.selectCaps = []
            this.selectAgent = null
            this.selectTag = null
            this.selectDay = null
            this.selectCat = []
        },

        merge() {
            let errlead = false
            this.selectedLeads.forEach((x) => {
                const child = x.attributes.child_leads.data.filter((y) => {
                    return y.attributes.disabled == false
                })
                if (child.length) {
                    if (this.selectedLeads.length > 2) {
                        errlead = true
                    } else {
                        for (const c of child) {
                            if (!this.selectedLeads.map((l) => l.id).includes(c.id)) {
                                errlead = true
                                break
                            }
                        }
                    }
                }
            })

            if (errlead) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('impossibile-unificare-dei-lead-con-derivati-unificare-prima-i-derivati'),
                    type: 'error'
                })
                return
            }

            this.mergeLeads = this.selectedLeads.map((x) => x.id)
            this.openmerge = true
        },
        addTo(id) {
            if (!id) {
                this.addto = this.selectedLeads.map((x) => x.id)
            } else {
                this.addto = [id]
            }
        },
        async updateUrl() {
            if (this.changinurl) return

            let query = { ...this.$route.query }
            this.changinurl = true
            if (this.options.page > 1) {
                if (this.$route.query.page != this.options.page) {
                    query.page = this.options.page
                }
            } else {
                delete query.page
            }
            if (this.options.itemsPerPage > 10) {
                if (this.$route.query.items != this.options.itemsPerPage) {
                    query.items = this.options.itemsPerPage
                }
            } else {
                delete query.items
            }
            if (this.options.sortBy.length) {
                if (this.$route.query.sortBy != this.options.sortBy) {
                    query.sortBy = this.options.sortBy
                    if (this.options.sortDesc.length && this.$route.query.sortDesc != this.options.sortDesc) {
                        query.sortDesc = this.options.sortDesc
                    }
                }
            } else {
                delete query.sortBy
                delete query.sortDesc
            }
            if (this.search) {
                if (this.$route.query.search != this.search) {
                    query.search = encodeURIComponent(this.search)
                }
            } else {
                delete query.search
            }

            if (this.selectProv.length) {
                if (this.$route.query.provincia != this.selectProv.map((x) => x.id).join(',')) {
                    query.provincia = this.selectProv.map((x) => x.id).join(',')
                }
            } else {
                delete query.provincia
            }

            if (this.selectCity.length) {
                if (this.$route.query.citta != this.selectCity.map((x) => x.id).join(',')) {
                    query.citta = this.selectCity.map((x) => x.id).join(',')
                }
            } else {
                delete query.citta
            }

            if (this.selectCaps.length) {
                if (this.$route.query.cap != this.selectCaps.join(',')) {
                    query.cap = this.selectCaps.join(',')
                }
            } else {
                delete query.cap
            }
            if (this.selectCat.length) {
                if (
                    this.$route.query.cat !=
                    this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                ) {
                    query.cat = this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                }
            } else {
                delete query.cat
            }

            if (this.selectTag?.length) {
                if (this.$route.query.tag != this.selectTag.join(',')) {
                    query.tag = this.selectTag.join(',')
                }
            } else {
                delete query.tag
            }
            if (this.selectDay?.length) {
                if (this.$route.query.day != this.selectDay) {
                    query.day = this.selectDay
                }
            } else {
                delete query.day
            }
            if (this.$permission(this.$auth.utente.role.name, 'FullLeadTable') && this.selectAgent) {
                if (this.$route.query.agente != this.selectAgent.id) {
                    query.agente = this.selectAgent.id
                }
            } else {
                delete query.agente
            }

            this.$router
                .replace({
                    path: this.$route.path,
                    query: query
                })
                .catch((err) => {})

            await this.getDataFromApi()
            this.changinurl = false
        },
        itemRowBackground(item) {
            if (item.attributes.parent_lead.data) {
                return 'tab-red'
            } else if (item.attributes.edit_agents.data.length) {
                return 'tab-yellow'
            }
        },
        getMapUrl(id) {
            const item = this.leads.find((x) => x.id == id)
            return this.$getMapUrl(item)
        },
        getAgents(zones) {
            if (!zones?.length) return ''
            let agents = new Set()
            zones.forEach((z) => {
                if (!z.attributes.agents.data?.length) return
                z.attributes.agents.data.forEach((a) => {
                    agents.add(a.attributes.Name)
                })
            })
            return [...agents].join(',')
        },
        getAgentsList(val) {
            if (val.length < 2) {
                return
            }
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getAgentsFromApi(val), 300)
        },
        async getAgentsFromApi(val) {
            this.loadingAgent = true
            const p = await this.$http.get(`agents`, {
                'pagination[pageSize]': 200,
                'populate': ['zones'],
                'filters': { Name: { $containsi: val } }
            })
            if (!p.data.length) {
                this.loadingAgent = false
                return
            }
            this.Agents = p.data.map((x) => {
                if (!x.attributes.zones.data.length) return
                return { id: x.id, name: x.attributes.Name, zone: x.attributes.zones.data.map((y) => y.id) }
            })

            this.loadingAgent = false
        },
        async getAgentsFromApiId(val) {
            this.loadingAgent = true
            const p = await this.$http.get(`agents`, {
                'pagination[pageSize]': 200,
                'populate': ['zones'],
                'filters': { id: { $eq: val } }
            })
            if (!p.data.length) {
                this.loadingAgent = false
                return
            }

            let pp = p.data.find((x) => {
                if (!x.attributes.zones.data.length) return
                return x
            })
            if (pp) {
                this.Agents = [{ id: pp.id, name: pp.attributes.Name, zone: pp.attributes.zones.data.map((y) => y.id) }]

                this.selectAgent = { id: pp.id, name: pp.attributes.Name, zone: pp.attributes.zones.data.map((y) => y.id) }
            }
            this.loadingAgent = false
        },
        async getCategoriesFromApi() {
            const p = await this.$http.get(`categories`, {
                'pagination[pageSize]': 200,
                'sort': 'name'
            })
            if (p.data.length) {
                this.categorie = p.data.map((x) => {
                    return { id: x.id, value: x.attributes.name, name: x.attributes.name }
                })
                this.categorie.push({ id: null, value: '', name: 'Senza Categoria' })
            }
        },
        getTagsList(val) {
            if (val.length < 2) {
                return
            }
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getTagsFromApi(val), 300)
        },
        async getTagsFromApi(val) {
            this.loadingTag = true
            const p = await this.$http.get(`tags`, {
                'pagination[pageSize]': 200,
                'sort': 'label',
                'filters': { agent: { id: { $eq: this.$auth.utente.agent.id } }, label: { $containsi: val } }
            })
            if (p.data.length) {
                this.tags = p.data.map((x) => {
                    return { id: x.id, label: x.attributes.label }
                })
            }

            this.loadingTag = false
        },
        async getTagsFromApiId(val) {
            this.loadingTag = true
            let filters = {}
            let c = 0
            val.split(',').forEach((x) => {
                filters[`filters[$or][${c}][id][$eq]`] = x
                c++
            })
            let ci = []
            let cc = {}
            let pa = 1
            do {
                cc = await this.$http.get(`tags`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    ...filters
                })
                ci = ci.concat(cc.data)
                pa++
            } while (cc.meta.pagination.pageCount > cc.meta.pagination.page)
            if (!cc.data.length) {
                this.loadingTag = false
                return
            }
            this.tags = ci.map((x) => {
                return { id: x.id, label: x.attributes.label }
            })
            this.selectTag = ci.map((x) => x.id)
            this.loadingTag = false
        },
        getProvince(val) {
            /*if (val.length < 2) {
                return
            }*/
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getProvinceFromApi(val), 300)
        },
        async getProvinceFromApi(val) {
            this.loadingProv = true
            const p = await this.$http.get(`provinces`, {
                'pagination[pageSize]': 200,
                'populate': ['zones'],
                'filters': { Name: { $containsi: val } }
            })
            if (!p.data.length) {
                this.loadingProv = false
                return
            }
            this.Province = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })

            this.loadingProv = false
        },
        async getProvinceFromApiId(val) {
            this.loadingProv = true
            let filters = {}
            let c = 0
            val.split(',').forEach((x) => {
                filters[`filters[$or][${c}][id][$eq]`] = x
                c++
            })
            const p = await this.$http.get(`provinces`, {
                'pagination[pageSize]': 200,
                ...filters
            })
            if (!p.data.length) {
                this.loadingProv = false
                return
            }
            this.Province = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.selectProv = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingProv = false
        },
        getCities(val) {
            /*if (val.length < 2) {
                return
            }*/
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getCitiesFromApi(val), 300)
        },
        async getCitiesFromApi(val) {
            this.loadingCity = true
            this.Cities = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`cities`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'Name',
                    'filters': { Name: { $containsi: val } },
                    'populate': ['Province']
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            if (!c.data.length) {
                this.loadingCity = false
                return
            }

            this.Cities = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name, prov: x.attributes.Province.data.attributes.Name }
            })
            this.loadingCity = false
        },
        async getCitiesFromApiId(val) {
            this.loadingCity = true
            let filters = {}
            let c = 0
            val.split(',').forEach((x) => {
                filters[`filters[$or][${c}][id][$eq]`] = x
                c++
            })
            let ci = []
            let cc = {}
            let pa = 1
            do {
                cc = await this.$http.get(`cities`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'populate': ['Province'],
                    ...filters
                })
                ci = ci.concat(cc.data)
                pa++
            } while (cc.meta.pagination.pageCount > cc.meta.pagination.page)
            if (!cc.data.length) {
                this.loadingCity = false
                return
            }
            this.Cities = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name, prov: x.attributes.Province.data.attributes.Name }
            })
            this.selectCity = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name, prov: x.attributes.Province.data.attributes.Name }
            })
            this.loadingCity = false
        },
        addCap() {
            if (!this.cap) return
            this.selectCaps.push(this.cap)
            this.cap = null
        },
        removeCap(i) {
            this.selectCaps.splice(i, 1)
        },
        setFilter() {
            this.options.page = 1
            this.updateUrl()
            this.modalFilter = false
        },
        showDeleteDialog(leadId) {
            this.leadToDelete = leadId
            this.deleteDialogMsg = this.$t('sei-sicuro-di-voler-eliminare-il-lead')
            this.deleteDialogCall = true
            this.showDeleteConfirmation = true
        },
        showDeleteDialogSelect() {
            this.deleteDialogMsg = this.$t('sei-sicuro-di-voler-eliminare-i-lead-selezionati')
            this.deleteDialogCall = false
            this.showDeleteConfirmation = true
        },
        deleteL(type = false) {
            if (type) {
                this.deleteLead()
            } else {
                this.deleteSelect()
            }
        },
        async deleteLead() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteLead')) {
                return
            }
            try {
                //await this.$http.delete(`leads/${this.leadToDelete}`)
                await this.$http.put(`leads/${this.leadToDelete}`, {
                    data: { disabled: true }
                })
                /*await this.$http.post(`actionlogs`, {
                    data: { agent: this.$auth.utente.agent.id, lead: this.leadToDelete, action: 'disable', date: this.$moment().format() }
                })*/

                await this.getDataFromApi()

                this.cancelDelete()
            } catch (error) {
                console.error(error)

                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-del-lead'),
                    type: 'error'
                })

                this.cancelDelete()
            }
        },

        cancelDelete() {
            this.showDeleteConfirmation = false
            this.leadToDelete = null
            this.deleteDialogMsg = ''
            this.deleteDialogCall = false
        },
        async importLeads() {
            if (!this.$permission(this.$auth.utente.role.name, 'importcsv')) {
                return
            }
            try {
                const result = await this.$http.get('importcsv')

                if (result === 'import Iniziato') {
                    this.$notify({
                        group: 'notifica',
                        title: this.$t('successo'),
                        text: this.$t('importazione-iniziata-con-successo'),
                        type: 'success'
                    })
                } else if (result === 'nessun file trovato') {
                    this.$notify({
                        group: 'notifica',
                        title: this.$t('attenzione'),
                        text: this.$t('nessun-file-trovato-per-limportazione'),
                        type: 'warn'
                    })
                } else {
                    this.$notify({
                        group: 'notifica',
                        title: this.$t('errore'),
                        text: this.$t('errore-durante-limportazione-dei-leads'),
                        type: 'error'
                    })
                }
            } catch (error) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-durante-la-richiesta-di-importazione-dei-leads'),
                    type: 'error'
                })
                console.error(error)
            }
        },

        async getDataFromApi() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            let filters = {}

            filters[`filters[$and][0][disabled][$eq]`] = false
            let cc = 1
            if (this.search) {
                filters[`filters[$and][${cc}][Name][$containsi]`] = this.search
                cc++
            }
            if (this.selectProv.length) {
                let c = 0
                this.selectProv.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][Province][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            if (this.selectCity.length) {
                let c = 0
                this.selectCity.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][City][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            if (this.selectAgent) {
                let c = 0
                this.selectAgent.zone.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][zones][id][$eq]`] = x
                    c++
                })
                cc++
            }
            if (this.selectCaps?.length) {
                let c = 0
                this.selectCaps.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][CAP][$eq]`] = x
                    c++
                })
                cc++
            }
            if (this.selectCat?.length) {
                let c = 0
                this.selectCat.forEach((x) => {
                    if (x == '') {
                        filters[`filters[$and][${cc}][$or][${c}][Category][$null]`] = true
                        c++
                    }
                    filters[`filters[$and][${cc}][$or][${c}][Category][$eq]`] = x
                    c++
                })
                cc++
            }
            if (this.selectTag?.length) {
                let c = 0
                this.selectTag.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][tags][id][$eq]`] = x
                    c++
                })
                cc++
            }
            if (this.selectDay) {
                let c = 0
                filters[`filters[$and][${cc}][$and][${c}][Opening][${this.selectDay}][morningClosed][$eq]`] = false
                filters[`filters[$and][${cc}][$and][${c}][Opening][${this.selectDay}][afternoonClosed][$eq]`] = false
                cc++
            }
            if (!this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                if (!this.userZones.length) {
                    this.loading = false
                    return
                }
                let c = 0
                this.userZones.forEach((zoneid) => {
                    filters[`filters[$and][${cc}][$or][${c}][zones][id][$eq]`] = zoneid
                    c++
                })
                cc++
                filters[`filters[$and][${cc}][child_leads][id][$null]`] = true
                //filters[`filters[$and][${cc + 1}][$or][0][edit_agents][id][$eq]`] = this.$auth.utente.agent.id
                //filters[`filters[$and][${cc + 1}][$or][1][edit_agents][id][$null]`] = true
            }
            let sort = 'Name'
            if (sortBy.length) {
                const el = this.headers.find((x) => x.value == sortBy[0])
                sort = el.field.concat(sortDesc[0] ? ':desc' : '')
            }
            const docs = await this.$http.get('leads', {
                'pagination[pageSize]': itemsPerPage,
                'pagination[page]': page,
                'populate': [
                    'Province',
                    'City',
                    'zones',
                    'zones.agents',
                    'parent_lead',
                    'child_leads',
                    'edit_agents',
                    'tags',
                    'Opening',
                    'Opening.mon',
                    'Opening.tue',
                    'Opening.wed',
                    'Opening.thu',
                    'Opening.fri',
                    'Opening.sat',
                    'Opening.sun'
                ],
                'sort': sort,
                ...filters
            })

            this.leads = docs.data || docs

            this.totalLeads = docs.meta.pagination.total ? docs.meta.pagination.total : 0
            this.loading = false
        },

        showAllOpen(value) {
            this.selectedOpening = this.$opening2UI(value)

            this.showAllOpenDialog = true
        },
        /* async deleteAll() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteLead')) {
                return
            }
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`leads`, { 'fields': ['Name'], 'pagination[pageSize]': 200, 'pagination[page]': pa })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            for (const x of ci) {
                let a = await this.$http.delete(`leads/${x.id}`)
            }
            this.getDataFromApi()
        },*/
        async deleteSelect() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteLead')) {
                return
            }

            try {
                for (const lead of this.selectedLeads) {
                    //await this.$http.delete(`leads/${lead.id}`)
                    await this.$http.put(`leads/${lead.id}`, {
                        data: { disabled: true }
                    })
                    /*await this.$http.post(`actionlogs`, {
                        data: { agent: this.$auth.utente.agent.id, lead: lead.id, action: 'disable', date: this.$moment().format() }
                    })*/
                }

                this.getDataFromApi()
                this.selectedLeads = []
                this.cancelDelete()
                this.$notify({
                    group: 'notifica',
                    title: this.$t('successo'),
                    text: this.$t('leads-selezionati-cancellati-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.error(error)
                this.cancelDelete()
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-dei-leads-selezionati'),
                    type: 'error'
                })
            }
        },
        async openNewTag() {
            await this.getTagsFromApi()
            this.showTag = true
        },
        async addTag() {
            //crea tag su server
            const tag = await this.$http.post(`tags`, {
                data: {
                    label: this.searchNewTag,
                    agent: this.$auth.utente.agent.id
                }
            })
            this.tags.push({ id: tag.data.id, label: this.searchNewTag })
            //sortare i tags per la label
            this.newTags.push(tag.data.id)
        },
        async salvaTag() {
            const leads = this.selectedLeads.map((x) => x.id)
            this.newTags.forEach(async (x) => {
                const tag = await this.$http.put(`tags/${x}`, {
                    data: {
                        leads: { connect: leads }
                    }
                })
            })
            this.showTag = false
            this.$notify({
                group: 'notifica',
                title: this.$t('successo'),
                text: this.$t('tag-aggiunti-con-successo'),
                type: 'success'
            })
        }
    }
}
</script>
<style>
/* Stile CSS personalizzato */
.no-underline {
    text-decoration: none;
}
</style>
