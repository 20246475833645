<template>
    <v-container fluid>
        <loading v-model="loading" />
        <!-- TITOLO -->
        <v-row>
            <v-col cols="12">
                <h1 class="pt-16 text-center">{{ $t('modifica') }} {{ $t('coordinate') }}</h1>
            </v-col>
        </v-row>
        <v-card class="overflow-hidden" color="primary" dark>
            <v-toolbar color="primary darken-1">
                <a @click="$router.go(-1)">
                    <v-icon class="mr-3 ml-4">arrow_back</v-icon>
                </a>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="saveCoordinates" :disabled="loading">{{ $t('salva') }}</v-btn>
                    <v-btn color="error" @click="$router.go(-1)" :disabled="loading">{{ $t('annulla') }}</v-btn>
                </v-card-actions>
            </v-toolbar>

            <v-card-text>
                <v-row class="pa-4">
                    <!-- Regione -->
                    <v-col cols="12" sm="3">
                        <v-text-field v-model="cityData.region" :label="$t('regione')" outlined readonly></v-text-field>
                    </v-col>

                    <!-- Provincia  -->
                    <v-col cols="12" sm="3">
                        <v-text-field v-model="cityData.province" :label="$t('provincia')" outlined readonly></v-text-field>
                    </v-col>

                    <!-- Citta -->
                    <v-col cols="12" sm="3">
                        <v-text-field v-model="cityData.city" :label="$t('citta')" outlined readonly></v-text-field>
                    </v-col>
                    <!-- Citta -->
                    <v-col cols="12" sm="3">
                        <v-text-field v-model="cityData.caps" :label="$t('cap')" outlined readonly></v-text-field>
                    </v-col>
                    <!-- Sinonimi provicia -->
                    <v-col cols="12" sm="6">
                        <v-card outlined>
                            <v-card-title class="subtitle-1">{{ $t('sinonimi-provincia') }}</v-card-title>
                            <v-card-text>
                                <!-- Input per aggiungere nuovi Sinonimi -->
                                <v-form @submit.prevent="addProvinceSynonym">
                                    <v-row>
                                        <v-col cols="8">
                                            <v-text-field v-model="newProvinceSynonym" :label="$t('aggiungi-sinonimi')" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="d-flex align-center">
                                            <v-btn color="success" small :disabled="!newProvinceSynonym" @click="addProvinceSynonym">
                                                <v-icon left>add</v-icon>
                                                Aggiungi
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <!-- Chip per visualizzare i Sinonimi esistenti con opzione per rimuoverli -->
                                <v-chip-group column>
                                    <v-chip
                                        v-for="(synonym, index) in provinceSynonyms"
                                        :key="'prov-syn-' + index + '-' + provinceForceRenderKey"
                                        color="primary"
                                        text-color="white"
                                        close
                                        @click:close="removeProvinceSynonym(index)"
                                    >
                                        {{ synonym }}
                                    </v-chip>
                                    <v-chip v-if="!provinceSynonyms || provinceSynonyms.length === 0" color="grey lighten-1" text-color="white" small>
                                        Nessun sinonimo
                                    </v-chip>
                                </v-chip-group>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!-- Sinonimi città -->
                    <v-col cols="12" sm="6">
                        <v-card outlined>
                            <v-card-title class="subtitle-1">{{ $t('sinonimi-citta') }}</v-card-title>
                            <v-card-text>
                                <!-- Input per aggiungere nuovi Sinonimi -->
                                <v-form @submit.prevent="addCitySynonym">
                                    <v-row>
                                        <v-col cols="8">
                                            <v-text-field v-model="newCitySynonym" :label="$t('aggiungi-sinonimi')" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="d-flex align-center">
                                            <v-btn color="success" small :disabled="!newCitySynonym" @click="addCitySynonym">
                                                <v-icon left>add</v-icon>
                                                Aggiungi
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <!-- Chip per visualizzare i Sinonimi esistenti con opzione per rimuoverli -->
                                <v-chip-group column>
                                    <v-chip
                                        v-for="(synonym, index) in cityData.synonym"
                                        :key="'syn-' + index + '-' + forceCityForceRenderKey"
                                        color="primary"
                                        text-color="white"
                                        close
                                        @click:close="removeCitySinonim(index)"
                                    >
                                        {{ synonym }}
                                    </v-chip>
                                    <v-chip v-if="!cityData.synonym || cityData.synonym.length === 0" color="grey lighten-1" text-color="white" small>
                                        Nessun sinonimo
                                    </v-chip>
                                </v-chip-group>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!-- Pannello coordinate provincia -->
                    <v-col cols="12" sm="6">
                        <v-card height="100%" class="mx-auto">
                            <v-app-bar dark color="black">
                                <v-card-title>{{ $t('coordinate-provincia') }} </v-card-title>
                                <v-spacer></v-spacer>
                            </v-app-bar>

                            <v-card-text>
                                <v-text-field v-model="provinceData.latitude" :label="$t('latitudine')" class="mt-4" outlined></v-text-field>

                                <v-text-field v-model="provinceData.longitude" :label="$t('longitudine')" class="mt-4" outlined></v-text-field>

                                <v-btn color="primary" block class="mt-4" @click="updateProvinceCoordinate">
                                    {{ $t('ottieni-coordinate') }}
                                </v-btn>

                                <v-alert v-if="error" type="error" class="mt-4">
                                    {{ error }}
                                </v-alert>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!-- Pannello coordinate città -->
                    <v-col cols="12" sm="6">
                        <v-card height="100%" class="mx-auto">
                            <v-app-bar dark color="black">
                                <v-card-title>{{ $t('coordinate-citta') }} </v-card-title>
                                <v-spacer></v-spacer>
                            </v-app-bar>

                            <v-card-text>
                                <v-text-field v-model="cityData.latitude" :label="$t('latitudine')" class="mt-4" outlined></v-text-field>

                                <v-text-field v-model="cityData.longitude" :label="$t('longitudine')" class="mt-4" outlined></v-text-field>

                                <v-btn color="primary" block class="mt-4" @click="updateCityCoordinate">
                                    {{ $t('ottieni-coordinate') }}
                                </v-btn>

                                <v-alert v-if="error" type="error" class="mt-4">
                                    {{ error }}
                                </v-alert>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row> </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import loading from '@/components/loading.vue'

export default {
    name: 'CityCoordinateDetail',
    components: {
        loading
    },
    data() {
        return {
            loading: false,
            error: null,
            cityData: {
                id: null,
                city: '',
                province: '',
                region: '',
                caps: [],
                synonym: [],
                latitude: null,
                longitude: null
            },
            provinceData: {
                latitude: null,
                longitude: null
            },

            //elementi sinonimi provincia
            provinceSynonyms: [],
            newProvinceSynonym: '',
            provinceForceRenderKey: 0,
            //elementi sinonimi città
            newCitySynonym: '',
            forceCityForceRenderKey: 0
        }
    },

    async created() {
        if (this.$route.params.id) {
            await this.getCityData(this.$route.params.id)
        } else {
            this.$router.push('/admin/updateCoordinates')
        }
    },
    computed: {
        //rimozione sinonimi reattiva
        synonyms() {
            return [...this.cityData.synonym]
        }
    },
    methods: {
        async getCityData(id) {
            this.loading = true
            try {
                const response = await this.$http.get(`cities/${id}`, {
                    populate: ['Province', 'Province.synonym', 'synonym']
                })

                const cityData = response.data

                // Estratti i dati dalla risposta
                this.cityData = {
                    id: cityData.id,
                    city: cityData.attributes.Name,
                    province: cityData.attributes.Province.data.attributes.Name,
                    provinceId: cityData.attributes.Province.data.id,
                    region: cityData.attributes.Province.data.attributes.Regione,
                    caps: cityData.attributes.Cap || [],
                    latitude: cityData.attributes.latitude,
                    longitude: cityData.attributes.longitude
                }
                // Coordinate della provincia
                this.provinceData = {
                    latitude: cityData.attributes.Province.data.attributes.latitude || null,
                    longitude: cityData.attributes.Province.data.attributes.longitude || null
                }

                // Gestione dei sinonimi citta
                if (cityData.attributes.synonym && cityData.attributes.synonym.length > 0) {
                    this.cityData.synonym = cityData.attributes.synonym.map((s) => s.Name || '').filter(Boolean)
                } else {
                    this.cityData.synonym = []
                }

                // Gestione dei sinonimi provincia
                if (cityData.attributes.Province.data.attributes.synonym && cityData.attributes.Province.data.attributes.synonym.length > 0) {
                    this.provinceSynonyms = cityData.attributes.Province.data.attributes.synonym.map((s) => s.Name || '').filter(Boolean)
                } else {
                    this.provinceSynonyms = []
                }
                //console.log(response, this.provinceSynonyms, 'synonym ')
            } catch (error) {
                console.error('Errore nel caricamento dei dati della città:', error)
                this.error = 'Errore nel caricamento dei dati. Riprova più tardi.'
            } finally {
                this.loading = false
            }
        },

        /**
         * Recupera le coordinate geografiche dalla API esterna
         * Utilizza i dati della città corrente per ottenere le coordinate
         */
        async updateCityCoordinate() {
            this.loading = true
            this.error = null

            try {
                const res = await this.$http.post(`location`, {
                    type: 'addr',
                    data: {
                        cap: this.cityData.caps[0] || '',
                        city: this.cityData.city,
                        province: this.cityData.province,
                        country: 'Italy'
                    }
                })

                if (res?.status == 'OK') {
                    this.cityData.latitude = res.result.lat.toString()
                    this.cityData.longitude = res.result.lng.toString()

                    this.$notify({
                        group: 'notifica',
                        title: 'Successo',
                        text: 'Coordinate aggiornate con successo',
                        type: 'success'
                    })
                } else {
                    this.error = "Coordinate non trovate. Controlla l'indirizzo."
                }
            } catch (error) {
                console.error('Errore nel recupero delle coordinate:', error)
                this.error = 'Errore nel recupero delle coordinate. Riprova più tardi.'
            } finally {
                this.loading = false
            }
        },

        /**
         * Salva le coordinate modificate nel database
         * Verifica che entrambe le coordinate siano presenti
         */
        async saveCoordinates() {
            if (!this.cityData.latitude || !this.cityData.longitude) {
                this.error = 'Inserisci entrambe le coordinate prima di salvare.'
                return
            }

            this.loading = true
            try {
                // Preparazione dei sinonimi nel formato corretto per un componente ripetibile
                const citySynonymData = this.cityData.synonym.map((name) => {
                    return {
                        Name: name
                    }
                })

                const updateCityData = {
                    data: {
                        latitude: this.cityData.latitude,
                        longitude: this.cityData.longitude,
                        Cap: this.cityData.caps,
                        synonym: citySynonymData
                    }
                }
                console.log('Aggiornamento dati città:', updateCityData)

                await this.$http.put(`cities/${this.cityData.id}`, updateCityData)

                // Preparazione dei sinonimi provincia
                const provinceSynonymData = this.provinceSynonyms.map((name) => ({
                    Name: name
                }))
                // Salvataggio dati della provincia
                const updateProvinceData = {
                    data: {
                        synonym: provinceSynonymData
                    }
                }
                // Aggiungi le coordinate provincia solo se sono presenti
                if (this.provinceData.latitude && this.provinceData.longitude) {
                    updateProvinceData.data.latitude = this.provinceData.latitude
                    updateProvinceData.data.longitude = this.provinceData.longitude
                }
                console.log('Aggiornamento dati provincia:', updateProvinceData)
                await this.$http.put(`provinces/${this.cityData.provinceId}`, updateProvinceData)
                this.$notify({
                    group: 'notifica',
                    title: 'Successo',
                    text: 'Dati salvati con successo',
                    type: 'success'
                })
                this.$router.push('/admin/updateCoordinates')
            } catch (error) {
                console.error('Errore nel salvataggio dei dati:', error)
                this.error = 'Errore nel salvataggio dei dati. Riprova più tardi.'
            } finally {
                this.loading = false
            }
        },

        // caricare i sinonimi della provincia
        async getProvinceDetails(provinceId) {
            try {
                const response = await this.$http.get(`provinces/${provinceId}`, {
                    populate: ['synonym']
                })

                const provinceData = response.data
                // Carica le coordinate
                this.provinceData = {
                    latitude: provinceData.attributes.latitude || null,
                    longitude: provinceData.attributes.longitude || null
                }

                // Gestione dei sinonimi provincia
                if (provinceData.attributes.synonym && provinceData.attributes.synonym.length > 0) {
                    this.provinceSynonyms = provinceData.attributes.synonym.map((s) => s.Name || '').filter(Boolean)
                } else {
                    this.provinceSynonyms = []
                }

                console.log('Dettagli provincia caricati:', {
                    coordinate: this.provinceData,
                    sinonimi: this.provinceSynonyms
                })
            } catch (error) {
                console.error('Errore nel caricamento dei sinonimi della provincia:', error)
                this.error = 'Errore nel caricamento dei sinonimi. Riprova più tardi.'
            }
        },

        async updateProvinceCoordinate() {
            this.loading = true
            this.error = null

            try {
                const res = await this.$http.post(`location`, {
                    type: 'addr',
                    data: {
                        city: this.cityData.province,
                        province: this.cityData.region,
                        country: 'Italy'
                    }
                })

                if (res?.status == 'OK') {
                    this.provinceData.latitude = res.result.lat.toString()
                    this.provinceData.longitude = res.result.lng.toString()

                    this.$notify({
                        group: 'notifica',
                        title: 'Successo',
                        text: 'Coordinate provincia aggiornate con successo',
                        type: 'success'
                    })
                } else {
                    this.error = "Coordinate provincia non trovate. Controlla l'indirizzo."
                }
            } catch (error) {
                console.error('Errore nel recupero delle coordinate provincia:', error)
                this.error = 'Errore nel recupero delle coordinate provincia. Riprova più tardi.'
            } finally {
                this.loading = false
            }
        },

        // Metodo per aggiungere un nuovo sinonimo
        addCitySynonym() {
            if (!this.newCitySynonym) return

            // Verifica se il sinonimo esiste già
            if (!this.cityData.synonym.includes(this.newCitySynonym)) {
                this.cityData.synonym.push(this.newCitySynonym)
                this.$notify({
                    group: 'notifica',
                    title: 'Successo',
                    text: 'Sinonimo aggiunto correttamente',
                    type: 'success'
                })
            } else {
                this.$notify({
                    group: 'notifica',
                    title: 'Attenzione',
                    text: 'Questo sinonimo è già presente',
                    type: 'warning'
                })
            }

            // Reset del campo
            this.newCitySynonym = ''
        },

        // Metodo per rimuovere un sinonimi
        removeCitySinonim(index) {
            // Crea una copia profonda dell'intero oggetto cityData
            const newCityData = JSON.parse(JSON.stringify(this.cityData))

            // Rimuovi il sinonimo dalla copia
            newCityData.synonym.splice(index, 1)

            // Sostituisci completamente l'oggetto cityData
            this.cityData = newCityData

            // Forza il rendering incrementando la chiave
            this.forceCityRenderKey++

            // Log per debug
            console.log('Sinonimi dopo la rimozione:', this.cityData.synonym)
            this.$notify({
                group: 'notifica',
                title: 'Successo',
                text: 'Sinonimo rimosso correttamente',
                type: 'success'
            })
        },
        // Metodo per aggiungere un nuovo sinonimo alla provincia
        addProvinceSynonym() {
            if (!this.newProvinceSynonym) return

            if (!this.provinceSynonyms.includes(this.newProvinceSynonym)) {
                this.provinceSynonyms.push(this.newProvinceSynonym)
                this.$notify({
                    group: 'notifica',
                    title: 'Successo',
                    text: 'Sinonimo provincia aggiunto correttamente',
                    type: 'success'
                })
            } else {
                this.$notify({
                    group: 'notifica',
                    title: 'Attenzione',
                    text: 'Questo sinonimo provincia è già presente',
                    type: 'warning'
                })
            }

            this.newProvinceSynonym = ''
        },
        // Metodo per rimuovere un sinonimo dalla provincia
        removeProvinceSynonym(index) {
            // Crea una copia dell'array dei sinonimi provincia
            const newProvinceSynonyms = [...this.provinceSynonyms]

            // Rimuovi il sinonimo
            newProvinceSynonyms.splice(index, 1)

            // Sostituisci l'array originale
            this.provinceSynonyms = newProvinceSynonyms

            // Forza il rendering incrementando la chiave
            this.provinceForceRenderKey++

            // Log per debug
            console.log('Sinonimi provincia dopo la rimozione:', this.provinceSynonyms)

            this.$notify({
                group: 'notifica',
                title: 'Successo',
                text: 'Sinonimo provincia rimosso correttamente',
                type: 'success'
            })
        }
    }
}
</script>
