<template>
    <v-container>
        <loading v-model="loading" />
        <!-------------- TITOLO
                    ----------------->
        <v-row v-if="title">
            <v-col cols="12">
                <h1 class="pt-16 text-center">
                    <span v-show="!editing && !newu">{{ $t('informazioni') }}</span>
                    <span v-show="newu">{{ $t('creazione') }}</span>
                    <span v-show="editing && !newu">{{ $t('modifica') }}</span>
                    {{ $tc('lead', 1) }}
                </h1>
            </v-col>
        </v-row>
        <!--------------  creazione/modifica agente DESKTOP
                       ----------------->
        <div class="">
            <v-card class="overflow-hidden" color="primary" dark>
                <v-toolbar color="primary darken-1" v-show="Object.values(buttons).some((x) => x === true)" v-if="!nobuttons">
                    <a @click="$router.go(-1)" v-if="buttons.backArrow">
                        <v-icon class="mr-3">arrow_back</v-icon>
                    </a>
                    <v-btn fab dark small color="green" @click="notify = !notify"><v-icon>update</v-icon></v-btn>

                    <div v-show="!editing" class="text-center ml-2" @click="showAutocomplete = !showAutocomplete">
                        <v-btn color="green" class="d-none d-sm-flex" x-smalll><v-icon class="mr-3">sell</v-icon> {{ $tc('tag', 1) }} </v-btn>
                        <v-btn fab dark small color="green" class="d-flex d-sm-none"> <v-icon color="white">sell</v-icon></v-btn>
                    </div>
                    <v-btn v-if="!isVisited" icon @click="isVisited = true"> <v-icon color="grey">check</v-icon></v-btn>
                    <v-btn v-if="isVisited" icon class="btn-disabled"> <v-icon color="green">done_all</v-icon></v-btn>
                    <v-bottom-sheet v-model="showAutocomplete" inset>
                        <v-sheet class="text-center" height="350px">
                            <v-container class="d-flex justify-center">
                                <v-btn class="mt-n6" color="error" @click="showAutocomplete = !showAutocomplete"> {{ $t('chiudi') }} </v-btn>
                            </v-container>
                            <v-autocomplete
                                chips
                                deletable-chips
                                multiple
                                outlined
                                :label="$tc('tag', 1)"
                                :readonly="editing"
                                v-model="oktags"
                                :items="tags"
                                item-text="label"
                                item-value="id"
                                :class="{ 'pa-12': $vuetify.breakpoint.smAndUp }"
                                :search-input.sync="searchTag"
                                @change="appendTag($event)"
                            >
                                <template v-slot:no-data>
                                    <v-list-item ripple @click="addTag()" v-show="searchTag?.length > 2" class="text-capitalize">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('aggiungi-', { to: $tc('tag', 1) }) }}: {{ searchTag }} </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />
                                </template>
                            </v-autocomplete>
                        </v-sheet>
                    </v-bottom-sheet>
                    <!--NOTIFY / reminder-->
                    <v-bottom-sheet v-model="notify" inset>
                        <v-sheet class="text-center" height="350px">
                            <v-container class="d-flex justify-center">
                                <v-btn class="mt-n6" color="error" @click="notify = !notify"> {{ $t('chiudi') }} </v-btn>
                                <v-spacer></v-spacer>

                                <v-btn class="mt-n6" color="success" @click="applyReminder"> {{ $t('applica') }} </v-btn>
                            </v-container>
                            <v-simple-table light>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center">{{ $t('frequenza-visite') }}</th>
                                            <th class="text-center">{{ $t('ultima-visita') }}</th>
                                            <th class="text-center">{{ $t('scadenza') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <v-row>
                                                    <v-col class="pr-4">
                                                        <v-slider v-model="reminder.days" class="align-center" :max="max" :min="min" hide-details>
                                                            <template v-slot:append>
                                                                <v-text-field
                                                                    v-model="reminder.days"
                                                                    class="mt-0 pt-0"
                                                                    hide-details
                                                                    single-line
                                                                    type="number"
                                                                    style="width: 60px"
                                                                ></v-text-field>
                                                            </template>
                                                        </v-slider>
                                                    </v-col>
                                                </v-row>
                                            </td>
                                            <td>{{ reminder.lastdate }}</td>
                                            <td>{{ reminder.expiredate }}</td>
                                        </tr>
                                        <p class="pa-4 text-left">
                                            {{ $t('indicare-il-numeri-di-giorni-per-impostare-la-frequenza-delle-visite') }} <br />
                                            {{ $t('verra-proposto-nella-pagina-giro-il-passaggio-dal-cliente-in-funzione-dellultima-visita') }}
                                        </p>
                                    </tbody>
                                </template>
                            </v-simple-table></v-sheet
                        ></v-bottom-sheet
                    >

                    <!--lead derivati-->
                    <v-menu
                        offset-y
                        v-if="
                            $permission($auth.utente.role.name, 'FullLeadTable') &&
                            lead.child_leads?.data.filter((y) => {
                                return y.attributes.disabled == false
                            }).length
                        "
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="rgb(231, 177, 105)" class="ml-4 mr-4 d-none d-sm-flex" dark v-bind="attrs" v-on="on">
                                <v-icon class="mr-2">supervisor_account </v-icon> {{ $t('lead-derivati') }}:
                                {{
                                    lead.child_leads.data.filter((y) => {
                                        return y.attributes.disabled == false
                                    }).length
                                }}
                            </v-btn>
                            <v-btn small color="rgb(231, 177, 105)" class="pointer d-flex d-sm-none ml-2" dark v-bind="attrs" v-on="on">
                                <v-icon>supervisor_account </v-icon>
                                {{
                                    lead.child_leads.data.filter((y) => {
                                        return y.attributes.disabled == false
                                    }).length
                                }}
                            </v-btn>
                        </template>
                        <v-list class="text-uppercase">
                            <v-list-item>
                                <v-list-item-title
                                    ><a
                                        :href="
                                            '/lead/' +
                                            lead.child_leads.data.filter((y) => {
                                                return y.attributes.disabled == false
                                            })[0].id
                                        "
                                        target="_blank"
                                    >
                                        {{
                                            lead.child_leads.data.filter((y) => {
                                                return y.attributes.disabled == false
                                            })[0].attributes.Name
                                        }}
                                    </a></v-list-item-title
                                >
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-spacer></v-spacer>
                    <!--EXPAN MORE TELEFONO-->
                    <div class="pointer d-flex d-sm-none">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="!editing" color="green" small dark v-bind="attrs" v-on="on">
                                    <v-icon>expand_more</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-btn
                                        color="red "
                                        class="ml-2"
                                        fab
                                        small
                                        @click="showDeleteConfirmation = true"
                                        v-show="!editing"
                                        v-if="buttons.delete && $permission($auth.utente.role.name, 'deleteLead')"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>

                                    <v-btn color="success " class="ml-2" fab small @click="Edit(true)" v-show="!editing" v-if="buttons.edit">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                    <v-btn
                                        color="success "
                                        class="ml-2"
                                        fab
                                        small
                                        @click="merge()"
                                        v-show="!editing"
                                        v-if="
                                            buttons.merge &&
                                            $permission($auth.utente.role.name, 'FullLeadTable') &&
                                            (lead.child_leads?.data.length || lead.parent_lead?.data)
                                        "
                                    >
                                        <v-icon>merge</v-icon>
                                    </v-btn>
                                    <v-btn color="success " class="ml-2" fab small @click="addTo(id)" v-show="!editing" v-if="buttons.addTo">
                                        <v-icon>add_location_alt</v-icon>
                                    </v-btn>
                                    <v-btn
                                        color="success "
                                        class="ml-2"
                                        fab
                                        small
                                        @click="
                                            $goCoordinate({
                                                lat: lead.Latitude,
                                                lng: lead.Longitude
                                            })
                                        "
                                        v-show="!editing && lead.Latitude && lead.Longitude"
                                        v-if="buttons.navigate"
                                    >
                                        <v-icon>near_me</v-icon>
                                    </v-btn>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <!--PULSANTI DESKTOP AZIONE-->
                    <div class="d-none d-sm-flex">
                        <v-btn
                            color="error "
                            class="ml-2"
                            fab
                            small
                            @click="showDeleteConfirmation = true"
                            v-show="!editing"
                            v-if="buttons.delete && $permission($auth.utente.role.name, 'deleteLead')"
                        >
                            <v-icon>delete</v-icon>
                        </v-btn>

                        <v-btn color="success " class="ml-2" fab small @click="Edit(true)" v-show="!editing" v-if="buttons.edit">
                            <v-icon>edit</v-icon>
                        </v-btn>
                        <v-btn
                            color="success "
                            class="ml-2"
                            fab
                            small
                            @click="merge()"
                            v-show="!editing"
                            v-if="
                                buttons.merge &&
                                $permission($auth.utente.role.name, 'FullLeadTable') &&
                                (lead.child_leads?.data.length || lead.parent_lead?.data)
                            "
                        >
                            <v-icon>merge</v-icon>
                        </v-btn>
                        <v-btn color="green " class="ml-2" fab small @click="addTo(id)" v-show="!editing" v-if="buttons.addTo">
                            <v-icon>add_location_alt</v-icon>
                        </v-btn>
                        <v-btn
                            color="green "
                            class="ml-2"
                            fab
                            small
                            @click="$goCoordinate({ lat: lead.Latitude, lng: lead.Longitude })"
                            v-show="!editing && lead.Latitude && lead.Longitude"
                            v-if="buttons.navigate"
                        >
                            <v-icon>near_me</v-icon>
                        </v-btn>
                    </div>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <!--PULSANTI CREA SALVA ANNULLA-->
                        <v-btn color="success" v-show="newu" @click="Create" :disabled="loading"> {{ $t('crea') }} </v-btn>
                        <v-btn color="success" v-show="editing && !newu" :disabled="loading" @click="Save"> {{ $t('salva') }} </v-btn>
                        <v-btn color="error" v-show="editing && !newu" @click="Edit(false)" :disabled="loading">
                            {{ $t('annulla') }}
                        </v-btn>
                    </v-card-actions>
                </v-toolbar>
                <!--INFORMAZIONI LEAD-->
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                            <v-col cols="12" :sm="sm(4)">
                                <v-text-field
                                    v-model="lead.Name"
                                    :label="$t('nome') + '*'"
                                    :rules="[$rules('Required'), $rules('maxLength250'), (v) => !v || !/[&%$£§#@!*+]/.test(v)].flat()"
                                    outlined
                                    hide-details
                                    :readonly="!editing"
                                    validate-on-blur
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="sm(4)">
                                <v-text-field
                                    v-model="lead.Phone"
                                    :label="$t('telefono')"
                                    :rules="[$rules('maxLength250')].flat()"
                                    outlined
                                    hide-details
                                    :readonly="!editing"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="sm(4)">
                                <v-text-field
                                    v-model="lead.Email"
                                    :label="$t('email')"
                                    :rules="[$rules('email'), $rules('maxLength250')].flat()"
                                    outlined
                                    hide-details
                                    :readonly="!editing"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" :sm="sm(3)">
                                <v-text-field
                                    v-model="lead.Address"
                                    :label="$t('indirizzo') + '*'"
                                    :rules="[$rules('Required'), $rules('maxLength250')].flat()"
                                    outlined
                                    hide-details
                                    :readonly="!editing"
                                    validate-on-blur
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="sm(3)">
                                <v-autocomplete
                                    v-model="lead.Province"
                                    :label="$tc('provincia', 1) + '*'"
                                    :items="Province"
                                    :rules="[$rules('Required')].flat()"
                                    item-text="name"
                                    return-object
                                    outlined
                                    hide-details
                                    :readonly="!editing"
                                    @change="updateCities(true)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" :sm="sm(3)">
                                <v-autocomplete
                                    v-model="lead.City"
                                    :label="$t('citta') + '*'"
                                    :items="Citta"
                                    :rules="[$rules('Required')].flat()"
                                    item-text="name"
                                    return-object
                                    outlined
                                    hide-details
                                    :readonly="!editing"
                                    @change="updateCaps(true)"
                                    v-if="lead.Province?.id !== null"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" :sm="sm(3)">
                                <v-autocomplete
                                    v-model="lead.CAP"
                                    :label="$t('cap') + '*'"
                                    :items="Cap"
                                    :rules="[$rules('Required')].flat()"
                                    outlined
                                    hide-details
                                    :readonly="!editing"
                                    v-if="lead.City?.id !== null"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <!--OPENING-->
                        <Opening :pedit="editing" class="pb-5" v-model="lead.Opening" />

                        <!--P.IVA E  CATEGORY-->
                        <v-row class="">
                            <v-col cols="12" :sm="sm(6)">
                                <v-text-field
                                    v-model="lead.PIVA"
                                    :label="$t('partita-iva')"
                                    :rules="[$rules('maxLength250')].flat()"
                                    outlined
                                    hide-details
                                    :readonly="!editing"
                                    validate-on-blur
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="sm2(6)">
                                <v-select
                                    v-model="lead.Category"
                                    :items="selectCategory"
                                    :label="$t('categoria') + '*'"
                                    :rules="$rules('Required')"
                                    outlined
                                    hide-details
                                    :readonly="!editing"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" v-if="$permission($auth.utente.role.name, 'FullLeadTable')">
                                <v-text-field v-model="lead.csvCategory" label="csvcategoria" outlined hide-details :readonly="true"></v-text-field>
                            </v-col>
                        </v-row>
                        <!--NOTE -->

                        <v-textarea
                            v-model="Note"
                            class="pt-3"
                            hide-details
                            outlined
                            auto-grow
                            rows="2"
                            name="input-7-4"
                            :label="$t('note')"
                            value=""
                            :readonly="!editing"
                        ></v-textarea>

                        <div v-if="lead.Social && $permission($auth.utente.role.name, 'FullLeadTable')" class="mt-4">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header> {{ $t('social') }} </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-text-field v-model="lead.Social.facebook" label="Facebook" class="mt-4" outlined :readonly="!editing"></v-text-field>
                                        <v-text-field v-model="lead.Social.twitter" label="Twitter" class="mt-n4" outlined :readonly="!editing"></v-text-field>
                                        <v-text-field
                                            v-model="lead.Social.instagram"
                                            label="Instagram"
                                            class="mt-n4"
                                            outlined
                                            :readonly="!editing"
                                        ></v-text-field>
                                        <v-text-field v-model="lead.Social.youtube" label="Youtube" class="mt-n4" outlined :readonly="!editing"></v-text-field>
                                        <v-text-field
                                            v-model="lead.Social.linkedin"
                                            label="Linkedin"
                                            class="mt-n4"
                                            outlined
                                            :readonly="!editing"
                                        ></v-text-field>
                                        <v-text-field v-model="lead.Url" label="Website" class="mt-n4" outlined :readonly="!editing"></v-text-field>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>

                        <div>
                            <h1 class="text-center ma-4">{{ $tc('visita', 2) }}</h1>
                            <v-simple-table dark>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">{{ $t('giorno-passaggio') }}</th>
                                            <th class="text-left">{{ $t('note-giro') }}</th>
                                            <th class="text-left">{{ $t('visitato') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(x, index) in plans" :key="x.id">
                                            <td>{{ x.attributes.day }}</td>
                                            <td>
                                                <v-btn color="blue" dark @click="noteVisit = index" :disabled="!x.attributes.note">
                                                    {{ $t('apri-note-giro') }}</v-btn
                                                >
                                            </td>
                                            <td>
                                                <v-icon v-if="(visited = true)" color="green">visibility</v-icon
                                                ><v-icon v-if="(visited = false)" color="red">visibility_off</v-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </v-form>
                    <v-bottom-sheet v-model="noted" v-if="noted">
                        <v-sheet class="text-center" height="250px">
                            <v-btn class="mt-n6" dark color="red" @click="noteVisit = null"> {{ $t('chiudi') }} </v-btn>

                            <v-textarea
                                filled
                                name="input-7-4"
                                class="pa-6 mb-6"
                                :label="$t('note-giro')"
                                :value="plans[noteVisit].attributes.note"
                                readonly
                            ></v-textarea>
                            <div class="py-3"></div>
                        </v-sheet>
                    </v-bottom-sheet>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="success" v-show="newu" @click="Create" :disabled="loading"> {{ $t('crea') }} </v-btn>
                    <v-btn color="success" v-show="editing && !newu" :disabled="loading" @click="Save"> {{ $t('salva') }} </v-btn>
                    <v-btn color="error" v-show="editing && !newu" @click="Edit(false)" :disabled="loading">
                        {{ $t('annulla') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>

        <v-dialog v-model="showDeleteConfirmation" max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="mt-6, text-center mt-6"> {{ $t('sei-sicuro-di-voler-eliminare-il-lead') }} </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="showDeleteConfirmation = false">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="Delete()">{{ $t('elimina') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <addToPlan v-model="addto"></addToPlan>
        <Merge v-model="openmerge" :pid="mergeLeads" :types="['leads', 'leads', 'leads']" :details="[null, null, null]"></Merge>
    </v-container>
</template>

<script>
import Opening from '@/components/Opening.vue'
import addToPlan from '@/components/addToPlan.vue'
import axios from 'axios'
import Merge from '@/components/merge.vue'
import loading from '@/components/loading.vue'

export default {
    name: 'cpLead',
    components: {
        Opening,
        addToPlan,
        Merge,
        loading
    },
    data() {
        return {
            id: null,
            lead: {
                Name: null,
                Phone: null,
                Email: null,
                Address: null,
                Province: { id: null, name: null },
                City: { id: null, name: null, cap: [] },
                CAP: null,
                Opening: null,
                Social: {
                    facebook: '',
                    instagram: '',
                    youtube: '',
                    linkedin: '',
                    twitter: '',
                    tiktok: ''
                },
                Longitude: null,
                Latitude: null,
                Category: null,
                Url: null,
                PIVA: null,
                CF: null,
                Edited: false
            },
            Note: null,
            bkNote: null,
            oktags: [],
            loading: false,
            editing: null,
            newu: null,
            valid: true,
            showDeleteConfirmation: false,
            Province: [],
            Citta: [],
            Cap: [],
            addto: [],
            tags: [],
            searchTag: null,
            selTags: [],
            mergeLeads: [],
            openmerge: false,
            defButtons: {
                delete: true,
                backArrow: true,
                edit: true,
                merge: true,
                addTo: true,
                navigate: true
            },
            showAutocomplete: false,
            notify: false,

            reminder: {
                days: null,
                lastdate: null,
                id: null,
                expiredate: null
            },
            min: 0,
            max: 180,
            plans: [],
            selectCategory: [],
            noteVisit: null,
            init: false,
            edited: false,
            editedNote: false,
            userprov: new Set(),
            usercit: new Set(),
            updateAddress: false
        }
    },

    props: {
        pedit: { type: Boolean, default: false },
        pnew: { type: Boolean, default: false },
        pid: { type: Number },
        pbuttons: { type: Object },
        title: { type: Boolean, default: true },
        sidebar: { type: Boolean, default: false },
        nobuttons: { type: Boolean, default: false }
    },
    watch: {
        'lead.Name': function (newVal) {
            if (newVal && /[&%$£§#@!*+]/.test(newVal)) {
                // Mostra solo la notifica
                this.$notify({
                    group: 'notifica',
                    title: this.$t('attenzione'),
                    text: this.$t('il-nome-non-puo-contenere-caratteri-speciali'),
                    type: 'warning'
                })
            }
        },
        'pid': {
            deep: true,
            handler(value, oldValue) {
                if (value !== oldValue) {
                    this.lead = {}

                    this.start()
                }
            }
        },
        'checkAddress'(newValue, oldValue) {
            if (newValue !== oldValue && this.editing && !this.loading) {
                this.updateAddress = true
            }
        },

        'lead': {
            deep: true,
            handler(value, oldValue) {
                if (this.init) {
                    this.edited = true
                }
            }
        },
        'Note': {
            handler(value, oldValue) {
                if (this.init) {
                    this.editedNote = true
                }
            }
        }
    },
    computed: {
        checkAddress() {
            return {
                address: this.lead.Address,
                province: this.lead.Province,
                city: this.lead.City,
                cap: this.lead.CAP
            }
        },

        noted() {
            return this.noteVisit != null
        },
        buttons: {
            get() {
                return { ...this.defButtons, ...this.pbuttons }
            },
            set(value) {
                return
            }
        },

        isVisited: {
            get() {
                if (!this.plans.length) return false
                //check if there is a plan visited today

                let visited = false
                let d = this.plans.find((x) => x.attributes.day == this.$moment().format('YYYY-MM-DD'))
                if (d?.attributes.visited) {
                    visited = true
                }
                return visited
            },
            async set(val) {
                //check if there is a plan for today
                const today = this.$moment().format('YYYY-MM-DD')
                let p = this.plans.find((x) => x.attributes.day == today)
                if (p) {
                    await this.$http.put(`plans/${p.id}`, {
                        data: {
                            visited: true
                        }
                    })
                } else {
                    const plans = await this.$http.get(`plans`, {
                        filters: {
                            agent: { id: { $eq: this.$auth.utente.agent.id } },
                            day: today
                        },
                        sort: ['order']
                    })
                    let order = plans.data.length ? plans.data[plans.data.length - 1].attributes.order + 1 : 0
                    let data = {
                        day: today,
                        lead: this.pid,
                        order: order,
                        agent: this.$auth.utente.agent.id,
                        visited: true
                    }

                    const lead = await this.$http.post(`plans`, {
                        data: data
                    })
                }
                this.getPlan()
            }
        }
    },

    async created() {
        this.start()
    },
    unmounted() {
        console.log('unmount')
    },

    methods: {
        async applyReminder() {
            try {
                if (!this.reminder.id) {
                    this.reminder.lastdate = this.$moment().format('YYYY-MM-DD')
                    const newReminder = await this.$http.post(`reminders`, {
                        data: {
                            lead: this.pid,
                            agent: this.$auth.utente.agent.id,
                            days: this.reminder.days,
                            lastdate: this.reminder.lastdate
                        }
                    })

                    this.reminder.id = newReminder.data.id
                } else {
                    const response = await this.$http.put(`reminders/${this.reminder.id}`, {
                        data: {
                            days: this.reminder.days,
                            lastdate: this.reminder.lastdate
                        }
                    })
                }
                this.notify = false
            } catch (error) {
                console.error("Errore durante l'applicazione del reminder", error)
            }
        },

        async start() {
            try {
                this.loading = true

                this.newu = this.pnew
                if (this.newu) this.editing = true
                if (!this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                    const user = await this.$http.get(`users/me`, { populate: ['agent', 'agent.zones'] })
                    if (user.agent.zones) {
                        user.agent.zones.forEach((z) => {
                            z.Rules.forEach((r) => {
                                this.userprov.add(r.prov)
                                r.cities.forEach((c) => {
                                    this.usercit.add({ id: c.id, name: c.name, cap: c.cap })
                                })
                            })
                        })
                    }
                    this.Province = [...this.userprov]
                } else {
                    const p = await this.$http.get(`provinces`, {
                        'pagination[pageSize]': 200,
                        'sort': 'Name'
                    })
                    this.Province = p.data.map((x) => {
                        return { id: x.id, name: x.attributes.Name }
                    })
                }
                await this.getCategoriesFromApi()
                await this.getTags()
                if (this.pid) {
                    let doc = await this.$http
                        .get(`leads/${this.pid}`, {
                            populate: [
                                'Province',
                                'City',
                                'Social',
                                'Opening',
                                'Opening.mon',
                                'Opening.tue',
                                'Opening.wed',
                                'Opening.thu',
                                'Opening.fri',
                                'Opening.sat',
                                'Opening.sun',
                                'tags',
                                'child_leads',
                                'parent_lead',
                                'zones'
                            ]
                        })
                        .catch((err) => {
                            console.log('errore', err)
                        })
                    if (!doc?.data.attributes) {
                        //this.$router.push('/')
                        this.$emit('error', { type: 'redirect', path: '/leads' })
                        return
                    }

                    //get reminder
                    const reminder = await this.$http
                        .get(`reminders`, {
                            filters: {
                                agent: { id: { $eq: this.$auth.utente.agent.id } },
                                lead: { id: { $eq: this.pid } }
                            }
                        })
                        .catch((err) => {
                            console.log('errore', err)
                        })
                    if (reminder.data.length) {
                        this.reminder = {
                            days: reminder.data[0].attributes.days,
                            lastdate: reminder.data[0].attributes.lastdate,
                            id: reminder.data[0].id,
                            expiredate: reminder.data[0].attributes.expiredate
                        }
                    }

                    this.getPlan()

                    if (!doc.data.attributes.Social) {
                        doc.data.attributes.Social = this.lead.Social
                    }
                    doc.data.attributes.Opening = this.$opening2UI(doc.data.attributes.Opening)

                    this.id = doc.data.id
                    this.lead = { ...doc.data.attributes }
                    this.Note = doc.data.attributes.Note
                    this.bkNote = doc.data.attributes.Note

                    if (!this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                        const trovato = this.$auth.utente.agent.zones.some((x) => {
                            const t = this.lead.zones.data.find((y) => y.id == x.id)
                            return t
                        })
                        if (!trovato) {
                            //this.$router.push('/')
                            this.$emit('error', { type: 'redirect', path: '/leads' })
                            return
                        }
                    }

                    if (doc.data.attributes.Province.data) {
                        this.lead.Province = {
                            id: doc.data.attributes.Province.data.id,
                            name: doc.data.attributes.Province.data.attributes.Name
                        }
                    } else {
                        this.lead.Province = { id: null, name: null }
                    }
                    if (doc.data.attributes.City.data) {
                        this.lead.City = {
                            id: doc.data.attributes.City.data.id,
                            name: doc.data.attributes.City.data.attributes.Name,
                            cap: doc.data.attributes.City.data.attributes.Cap
                        }
                    } else {
                        this.lead.City = { id: null, name: null, cap: [] }
                    }
                    if (doc.data.attributes.tags.data) {
                        this.oktags = doc.data.attributes.tags.data.map((x) => x.id)
                        this.selTags = doc.data.attributes.tags.data.map((x) => x.id)
                    }
                    await this.updateCities()
                    this.updateCaps()
                    if (this.pedit) {
                        this.Edit(true)
                    }
                }
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
                this.init = true
            }
        },
        async getCategoriesFromApi() {
            const p = await this.$http.get(`categories`, {
                'pagination[pageSize]': 200,
                'sort': 'name'
            })
            if (p.data.length) {
                this.selectCategory = p.data.map((x) => {
                    return x.attributes.name
                })
            }
        },
        async getPlan() {
            //get plan
            const plan = await this.$http
                .get(`plans`, {
                    filters: {
                        agent: { id: { $eq: this.$auth.utente.agent.id } },
                        lead: { id: { $eq: this.pid } }
                    },
                    sort: { day: 'asc' }
                })
                .catch((err) => {
                    console.log('errore', err)
                })
            this.plans = plan.data
        },
        merge() {
            let lst = []
            if (this.lead.parent_lead?.data) {
                lst = [this.lead.parent_lead.data.id, this.id].flat()
            } else {
                lst = [...[this.id], this.lead.child_leads.data.map((x) => x.id)].flat()
            }
            this.mergeLeads = lst
            this.openmerge = true
        },
        addTo(id) {
            this.addto = [id]
        },
        sm(v) {
            return this.sidebar ? 12 : v
        },

        sm2(b) {
            return this.sidebar ? 6 : b
        },
        openingChange(val) {
            this.lead.Opening = val
        },
        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },

        Edit(stato) {
            this.init = false
            if (stato) {
                this.leadOriginal = JSON.parse(JSON.stringify(this.lead))
            } else {
                this.lead = JSON.parse(JSON.stringify(this.leadOriginal))
                this.updateAddress = false
            }

            this.editing = stato
            this.$nextTick((x) => {
                this.init = true
            })
        },

        async Save() {
            if (this.loading) return
            if (!this.$refs.form.validate()) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-nel-form-verificare-i-campi'),
                    type: 'error'
                })
                return
            }
            try {
                this.loading = true
                //verifica coordinate
                if (!this.lead.Latitude || !this.lead.Longitude || this.updateAddress) {
                    // recupera coordinate

                    let c = await this.getCoordinate()
                    if (c) {
                        this.lead.Latitude = c.lat
                        this.lead.Longitude = c.lng
                    }
                }
                Object.keys(this.lead.Opening).forEach((key) => {
                    if (key == 'id') return
                    if (!this.lead.Opening[key].moTimeClose || !this.lead.Opening[key].afTimeOpen) {
                        this.lead.Opening[key].moTimeClose = '13:00'
                        this.lead.Opening[key].afTimeOpen = '13:00'
                        this.lead.Opening[key].hourContinued = true
                    }
                })
                if (!this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                    if ((!this.edited && this.editedNote) || this.lead.parent_lead.data != null) {
                        const lead = await this.$http.put(`leads/${this.id}`, {
                            data: {
                                ...this.lead,
                                Province: this.lead.Province.id,
                                City: this.lead.City.id,
                                Opening: this.$opening2DB(this.lead.Opening),
                                Note: this.Note,
                                Edited: this.lead.Edited || this.edited
                            }
                        })
                    } else {
                        const lead = await this.$http.post(`leads`, {
                            data: {
                                ...this.lead,
                                Province: this.lead.Province.id,
                                City: this.lead.City.id,
                                edit_agents: this.$auth.utente.agent.id,
                                parent_lead: this.id,
                                Opening: this.$opening2DB(this.lead.Opening),
                                Note: this.Note,
                                Edited: this.lead.Edited || this.edited
                            }
                        })
                        //this.$router.push(`/lead/${lead.data.id}`)
                        this.$emit('error', {
                            type: 'redirect',
                            path: `/lead/${lead.data.id}`
                        })
                    }
                } else {
                    const lead = await this.$http.put(`leads/${this.id}`, {
                        data: {
                            ...this.lead,
                            Province: this.lead.Province.id,
                            City: this.lead.City.id,
                            Opening: this.$opening2DB(this.lead.Opening),
                            Note: this.Note,
                            Edited: this.lead.Edited || this.edited
                        }
                    })
                }

                //success
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('salvataggio-avvenuto-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-nel-salvataggio'),
                    type: 'error'
                })
            } finally {
                this.editing = false
                this.edited = false
                this.editedNote = false
                this.loading = false
                this.updateAddress = false
            }
        },
        async getCoordinate() {
            try {
                const res = await this.$http.post(`location`, {
                    type: 'addr',
                    data: {
                        name: this.lead.Name,
                        address: this.lead.Address,
                        cap: this.lead.CAP,
                        city: this.lead.City.name,
                        province: this.lead.Province.name,
                        country: 'Italy'
                    }
                })
                if (res?.status == 'OK') {
                    return { lat: res.result.lat.toString(), lng: res.result.lng.toString() }
                } else {
                    //error coordinate
                    this.$notify({
                        group: 'notifica',
                        title: this.$t('coordinate-non-trovate'),
                        text: this.$t('controllare-o-modificare-lindirizzo'),
                        type: 'error'
                    })
                    return null
                }
            } catch (error) {
                //error coordinate
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore-nel-recupero-delle-coordinate'),
                    text: this.$t('prova-a-modificare-lindirizzo'),
                    type: 'error'
                })
                return null
            }
        },
        async Create() {
            if (this.loading) return
            if (!this.$refs.form.validate()) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-nel-form-verificare-i-campi'),
                    type: 'error'
                })
                return
            }
            try {
                this.loading = true

                let c = await this.getCoordinate()
                if (c) {
                    this.lead.Latitude = c.lat
                    this.lead.Longitude = c.lng
                }

                let data = {
                    ...this.lead,
                    Province: this.lead.Province.id,
                    City: this.lead.City.id,
                    Source: `Utente ${this.$auth.utente.id}`,
                    Opening: this.$opening2DB(this.lead.Opening),
                    Note: this.Note,
                    Edited: this.edited
                }
                if (!this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                    data.edit_agents = this.$auth.utente.agent.id
                }
                const newlead = await this.checkNewLead(data)
                if (newlead) {
                    const lead = await this.$http.post(`leads`, {
                        data: data
                    })

                    //success
                    this.$notify({
                        group: 'notifica',
                        title: this.$t('salvataggio'),
                        text: this.$t('salvataggio-avvenuto-con-successo'),
                        type: 'success'
                    })
                    this.$emit('error', { type: 'redirect', path: '/leads' })
                }
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('errore-nel-salvataggio'),
                    type: 'error'
                })
            } finally {
                //this.$router.push(`/`)
                this.loading = false
                this.updateAddress = false
            }
        },
        async checkNewLead(lead) {
            let doc = await this.$http
                .get(`leads`, {
                    filters: {
                        Name: { $eq: lead.Name },
                        Province: { id: { $eq: lead.Province } },
                        City: { id: { $eq: lead.City } },
                        CAP: { $eq: lead.CAP },
                        Address: { $eq: lead.Address },
                        Phone: { $eq: lead.Phone }
                    },
                    populate: ['Province', 'City']
                })
                .catch((err) => {
                    console.log('errore', err)
                })
            if (!doc.data.length) {
                //this.$router.push('/')
                return true
            } else {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('lead-gia-esistente'),
                    text: this.$t('esiste-gia-un-lead-con-le-stesse-informazioni'),
                    type: 'error'
                })
                return false
            }
        },

        async Delete() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteLead')) {
                return
            }
            try {
                await this.$http.put(`leads/${this.id}`, {
                    data: { disabled: true }
                })
                /*await this.$http.post(`actionlogs`, {
                    data: { agent: this.$auth.utente.agent.id, lead: this.id, action: 'disable', date: this.$moment().format() }
                })*/

                this.showDeleteConfirmation = false
                if (this.$route.path.includes('/map')) {
                    // Se siamo nella mappa, chiudi i dettagli e ricarica i lead
                    this.$emit('deleted')
                } else {
                    // Se siamo nella pagina del lead, reindirizza alla home
                    this.$emit('error', { type: 'redirect', path: '/' })
                }
            } catch (error) {
                console.error(error)

                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-del-lead'),
                    type: 'error'
                })
                this.showDeleteConfirmation = false
            }
        },
        async updateCities(clear = null) {
            if (clear) {
                //this.Citta = []
                this.lead.City = { id: null, name: null, cap: [] }
                //this.Cap = []
                this.lead.CAP = null
            }
            if (!this.lead.Province?.id) {
                this.Citta = []
                this.Cap = []
                return
            }

            await this.getCities(this.lead.Province)
            this.getCaps()
        },
        updateCaps(clear = null) {
            if (clear) {
                this.Cap = []
                this.lead.CAP = null
            }
            if (!this.lead.City?.id) {
                this.Cap = []
                return
            }
            this.getCaps()
        },
        async getCities(prov) {
            this.Citta = []
            //this.ruletmp.cities = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`cities`, {
                    'populate': ['Province'],
                    'filters': { Province: { id: { $eq: prov.id } } },
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'Name'
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            if (!this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                this.Citta = ci
                    .filter((x) => [...this.usercit].some((y) => y.id == x.id))
                    .map((x) => {
                        return { id: x.id, name: x.attributes.Name, cap: x.attributes.Cap }
                    })
            } else {
                this.Citta = ci.map((x) => {
                    return { id: x.id, name: x.attributes.Name, cap: x.attributes.Cap }
                })
            }
        },
        getCaps() {
            this.Cap = this.lead.City.cap
        },
        async getTags() {
            this.tags = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`tags`, {
                    'filters': { agent: { id: { $eq: this.$auth.utente.agent.id } } },
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'label'
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            this.tags = ci.map((x) => {
                return { id: x.id, label: x.attributes.label }
            })
        },
        async addTag() {
            //crea tag su server
            const tag = await this.$http.post(`tags`, {
                data: {
                    label: this.searchTag,
                    agent: this.$auth.utente.agent.id,
                    leads: { connect: [this.id] }
                }
            })
            await this.getTags()
            //aggiungi tag a lead
            if (tag.data?.id) {
                this.oktags.push(tag.data.id)
            }
        },
        async appendTag(event) {
            let add = event.filter((x) => !this.selTags.includes(x))
            let rem = this.selTags.filter((x) => !event.includes(x))
            if (add.length) {
                const tag = await this.$http.put(`tags/${add[0]}`, {
                    data: {
                        leads: { connect: [this.id] }
                    }
                })
            } else if (rem.length) {
                const tag = await this.$http.put(`tags/${rem[0]}`, {
                    data: {
                        leads: { disconnect: [this.id] }
                    }
                })
            }
            this.selTags = event
        }
    }
}
</script>
<style>
.col-sm-12 {
    margin-top: -10px;
}
.col-sm-6 {
    margin-top: -10px;
}

.btn-disabled {
    pointer-events: none;
}
</style>
