<template>
    <v-container fluid>
        <v-col cols="12" class="text-center">
            <v-row :class="{ 'mt-10': !$vuetify.breakpoint.mdAndUp, 'mt-10 pt-10': $vuetify.breakpoint.mdAndUp }">
                <!-------------- esporta lead  ----------------->

                <v-col cols="4" class="d-flex justify-center">
                    <v-card flat>
                        <div class="text-center" @click="confirmExportLeads">
                            <v-btn rounded color="orange" class="d-none d-md-flex" large
                                ><v-icon class="pa-2">download</v-icon> {{ $t('esporta', { page: this.$tc('lead', 2) }) }}
                            </v-btn>
                            <v-icon color="orange " class="pointer d-flex d-md-none mt-5" x-large>download</v-icon>
                        </div>
                    </v-card>
                </v-col>

                <!-------------- title ----------------->
                <v-col cols="4" class="d-flex justify-center">
                    <h1>
                        {{ $t('sezione', { page: this.$tc('marketing', 1) }) }}
                    </h1>
                </v-col>

                <!-------------- filter ----------------->
                <v-col cols="4" class="d-flex justify-center">
                    <div @click="modalFilter = !modalFilter">
                        <v-btn large rounded color="success" class="d-none d-md-flex">
                            <v-icon class="pa-2">filter_alt</v-icon> {{ $t('imposta-filtri') }}
                        </v-btn>
                        <v-icon color="success" class="d-flex d-md-none mt-5" x-large>filter_alt</v-icon>
                    </div>

                    <div class="text-center">
                        <v-bottom-sheet v-model="modalFilter">
                            <v-sheet class="text-center" height="auto">
                                <!-------------- reset filter ----------------->
                                <v-container cols="12">
                                    <v-row class="d-flex justify-center">
                                        <!-- Close button -->
                                        <v-col cols="3" class="d-flex justify-center" @click="modalFilter = !modalFilter">
                                            <v-btn class="mt-n8 d-none d-sm-flex" color="error">{{ $t('chiudi') }}</v-btn>
                                            <v-btn color="error" class="d-flex d-sm-none icon"><v-icon> cancel </v-icon></v-btn>
                                        </v-col>

                                        <!-- Reset filters button -->
                                        <v-col cols="6">
                                            <v-btn class="mt-n8" color="warning" @click="clearFilters">{{ $t('reset-filtri') }}</v-btn>

                                            <h1 class="mt-4">{{ $tc('filtro', 2) }}</h1>
                                        </v-col>

                                        <!-- Apply button -->
                                        <v-col cols="3" class="d-flex justify-center" @click="setFilter()">
                                            <v-btn class="mt-n8 d-none d-sm-flex" color="success">{{ $t('applica') }}</v-btn>
                                            <v-btn color="success" class="d-flex d-sm-none icon"> <v-icon> search </v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="pa-6">
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-autocomplete
                                                :label="$tc('provincia', 1)"
                                                item-text="name"
                                                :search-input.sync="searchProv"
                                                cache-items
                                                multiple
                                                hide-no-data
                                                v-model="selectProv"
                                                :loading="loadingProv"
                                                :items="Province"
                                                return-object
                                                solo-inverted
                                                flat
                                                chips
                                                deletable-chips
                                                clearable
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-autocomplete
                                                :label="$tc('citta', 1)"
                                                item-text="name"
                                                item-value="id"
                                                :search-input.sync="searchCity"
                                                cache-items
                                                multiple
                                                hide-no-data
                                                v-model="selectCity"
                                                :loading="loadingCity"
                                                :items="Cities"
                                                return-object
                                                solo-inverted
                                                flat
                                                chips
                                                deletable-chips
                                                clearable
                                            >
                                            </v-autocomplete
                                        ></v-col>
                                        <v-col cols="12" sm="4">
                                            <span v-for="(c, i) in selectCaps" :key="i">
                                                <v-chip close @click:close="removeCap(i)"> {{ c }} </v-chip>
                                            </span>
                                            <div class="d-flex flex-row">
                                                <v-text-field v-model="cap" :label="$tc('cap-insert', 1)" solo-inverted flat type="number"> </v-text-field>
                                                <v-icon class="ml-7 mt-n6" @click="addCap">add_circle</v-icon>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-autocomplete
                                                :label="$tc('zona', 1)"
                                                item-text="name"
                                                :search-input.sync="searchZona"
                                                cache-items
                                                multiple
                                                hide-no-data
                                                v-model="selectZona"
                                                :loading="loadingZona"
                                                :items="Zone"
                                                return-object
                                                solo-inverted
                                                flat
                                                chips
                                                deletable-chips
                                                clearable
                                            >
                                            </v-autocomplete
                                        ></v-col>
                                        <v-col cols="12" sm="6">
                                            <v-autocomplete
                                                :label="$tc('categoria', 1)"
                                                item-text="name"
                                                item-value="value"
                                                multiple
                                                hide-no-data
                                                v-model="selectCat"
                                                :items="categorie"
                                                solo-inverted
                                                flat
                                                chips
                                                deletable-chips
                                                clearable
                                            >
                                            </v-autocomplete
                                        ></v-col>

                                        <v-col cols="12" sm="4">
                                            <h4>{{ this.$t('email') }}</h4>

                                            <v-slider
                                                v-model="sliders.email.value"
                                                :tick-labels="[this.$t('tutti'), this.$t('con'), this.$t('senza')]"
                                                :max="2"
                                                step="1"
                                                ticks="always"
                                                tick-size="3"
                                            ></v-slider>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <h4>{{ this.$t('telefono') }}</h4>

                                            <v-slider
                                                v-model="sliders.telefono.value"
                                                :tick-labels="[this.$t('tutti'), this.$t('con'), this.$t('senza')]"
                                                :max="2"
                                                step="1"
                                                ticks="always"
                                                tick-size="3"
                                            ></v-slider>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <h4>{{ this.$tc('zona', 1) }}</h4>

                                            <v-slider
                                                v-model="sliders.zona.value"
                                                :tick-labels="[this.$t('tutti'), this.$t('con'), this.$t('senza')]"
                                                :max="2"
                                                step="1"
                                                ticks="always"
                                                tick-size="3"
                                            >
                                            </v-slider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <h4>website</h4>
                                            <v-slider
                                                v-model="sliders.website.value"
                                                :tick-labels="[this.$t('tutti'), this.$t('con'), this.$t('senza')]"
                                                :max="2"
                                                step="1"
                                                ticks="always"
                                                tick-size="3"
                                            ></v-slider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <h4>Facebook</h4>
                                            <v-slider
                                                v-model="sliders.facebook.value"
                                                :tick-labels="[this.$t('tutti'), this.$t('con'), this.$t('senza')]"
                                                :max="2"
                                                step="1"
                                                ticks="always"
                                                tick-size="3"
                                            ></v-slider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <h4>Instagram</h4>
                                            <v-slider
                                                v-model="sliders.instagram.value"
                                                :tick-labels="[this.$t('tutti'), this.$t('con'), this.$t('senza')]"
                                                :max="2"
                                                step="1"
                                                ticks="always"
                                                tick-size="3"
                                            ></v-slider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <h4>Linkedin</h4>
                                            <v-slider
                                                v-model="sliders.linkedin.value"
                                                :tick-labels="[this.$t('tutti'), this.$t('con'), this.$t('senza')]"
                                                :max="2"
                                                step="1"
                                                ticks="always"
                                                tick-size="3"
                                            ></v-slider>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-sheet>
                        </v-bottom-sheet>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <!------------- GRIGLIE ----------------->
        <v-card class="marginScroll">
            <v-data-table
                :headers="headers"
                :items="leads"
                :options.sync="options"
                :server-items-length="totalLeads"
                :loading="loading"
                class="pt-6"
                :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100], showFirstLastPage: true }"
                v-model="selectedLeads"
                @update:options="updateUrl"
                :mobile-breakpoint="1270"
            >
                <template #[`item.attributes.Email`]="{ value }">
                    <span v-if="value">
                        <a :href="'mailto:' + value"><v-icon>mail</v-icon></a>
                    </span>
                </template>
                <template #[`item.attributes.Url`]="{ value }">
                    <span v-if="value">
                        <a :href="value" target="_blank"><v-icon>language</v-icon></a>
                    </span>
                </template>
                <template #[`item.attributes.Social.facebook`]="{ value }">
                    <span v-if="value">
                        <a :href="value" target="_blank"><v-icon> $vuetify.icons.facebook </v-icon></a>
                    </span>
                </template>
                <template #[`item.attributes.Social.instagram`]="{ value }">
                    <span v-if="value">
                        <a :href="value" target="_blank"><v-icon> $vuetify.icons.instagram </v-icon></a>
                    </span>
                </template>
                <template #[`item.attributes.Social.linkedin`]="{ value }">
                    <span v-if="value">
                        <a :href="value" target="_blank"><v-icon> $vuetify.icons.linkedin </v-icon></a>
                    </span>
                </template>
                <template #[`item.attributes.zones.data`]="{ value }">
                    <span>{{ value.map((x) => x.attributes.Name).join(',') }}</span>
                </template>
            </v-data-table>
        </v-card>
        <!-- dialog sicuro di voler cancellare -->

        <v-dialog v-model="showExportConfirmation" max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="mt-6, text-center mt-6"> Stai esportanto più di {{ limitNumberExport }} leads sei sicuro?</v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="showExportConfirmation = false">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn color="red" text @click="exportLeads">{{ $t('esporta') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'Marketing',
    components: {},
    data() {
        return {
            leads: [],
            totalLeads: 0,
            loading: true,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: null,
                mustSort: null
            },
            timeoutQuery: null,
            selectedOpening: [],
            showExportConfirmation: false,
            limitNumberExport: 1000,
            deleteDialogCall: null,
            leadToDelete: null,
            modalFilter: false,

            headers: [
                { text: this.$tc('nome-azienda', 1), value: 'attributes.Name', field: 'Name', sortable: false },
                {
                    text: this.$tc('indirizzo', 1),
                    value: 'attributes.Address',
                    field: 'Address',
                    sortable: false
                },
                {
                    text: this.$tc('citta', 1),
                    value: 'attributes.City.data.attributes.Name',
                    field: 'City.Name',
                    sortable: false
                },
                {
                    text: this.$tc('cap', 1),
                    value: 'attributes.CAP',
                    field: 'CAP',
                    sortable: false
                },
                {
                    text: this.$tc('provincia', 1),
                    value: 'attributes.Province.data.attributes.Name',
                    field: 'Province.Name',
                    sortable: false
                },
                {
                    text: this.$tc('telefono', 1),
                    value: 'attributes.Phone',
                    field: 'Phone',
                    sortable: false
                },
                {
                    text: this.$tc('email', 1),
                    value: 'attributes.Email',
                    field: 'Email',
                    sortable: false
                },
                {
                    text: 'Website',
                    value: 'attributes.Url',
                    field: 'Url',
                    sortable: false
                },
                {
                    text: 'Facebook',
                    value: 'attributes.Social.facebook',
                    field: 'Social.facebook',
                    sortable: false
                },
                {
                    text: 'Instagram',
                    value: 'attributes.Social.instagram',
                    field: 'Social.instagram',
                    sortable: false
                },
                {
                    text: 'Linkedin',
                    value: 'attributes.Social.linkedin',
                    field: 'Social.linkedin',
                    sortable: false
                },
                {
                    text: this.$tc('categoria', 1),
                    value: 'attributes.Category',
                    field: 'Category',
                    sortable: false
                },
                {
                    text: this.$tc('zona', 1),
                    value: 'attributes.zones.data',
                    field: 'zones',
                    sortable: false
                },
                {
                    text: 'csvCategory',
                    value: 'attributes.csvCategory',
                    field: 'csvCategory',
                    sortable: false
                }
            ],
            searchProv: null,
            selectProv: [],
            loadingProv: false,
            Province: [],
            searchCity: null,
            selectCity: [],
            loadingCity: false,
            Cities: [],
            cap: null,
            selectCaps: [],
            selectedLeads: [],
            changinurl: false,

            addto: [],

            selectDay: null,
            loadingDay: false,

            mergeLeads: [],
            openmerge: false,
            showTag: false,
            newTags: [],
            searchNewTag: null,
            sliders: {
                email: { field: '[Email]', value: 0 },
                telefono: { field: '[Phone]', value: 0 },
                zona: { field: '[zones][id]', value: 0 },
                website: { field: '[Url]', value: 0 },
                facebook: { field: '[Social][facebook]', value: 0 },
                instagram: { field: '[Social][instagram]', value: 0 },
                linkedin: { field: '[Social][linkedin]', value: 0 }
            },
            categorie: [],
            selectCat: [],
            searchZona: null,
            selectZona: [],
            loadingZona: false,
            Zone: []
        }
    },
    watch: {
        searchProv(val) {
            if (this.$permission(this.$auth.utente.role.name, 'filterProvince')) {
                val && this.getProvince(val)
            } else {
                val
            }
        },
        searchCity(val) {
            if (this.$permission(this.$auth.utente.role.name, 'filterCity')) {
                val && this.getCities(val)
            } else {
                val
            }
        },
        searchZona(val) {
            val && this.getZone(val)
        }
    },
    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'AdminPage')) {
            this.$router.push('/')
        }
        this.changinurl = true
        if (this.$route.query.page) {
            this.options.page = parseInt(this.$route.query.page)
        }
        if (this.$route.query.items) {
            this.options.itemsPerPage = parseInt(this.$route.query.items)
        }
        /*if (this.$route.query.sortBy) {
            this.options.sortBy[0] = this.$route.query.sortBy
            if (this.$route.query.sortDesc) {
                this.options.sortDesc[0] = this.$route.query.sortDesc
            }
        }*/
        if (this.$route.query.cap) {
            this.selectCaps = this.$route.query.cap.split(',')
        }

        if (this.$route.query.provincia) {
            await this.getProvinceFromApiId(this.$route.query.provincia)
        }
        if (this.$route.query.citta) {
            await this.getCitiesFromApiId(this.$route.query.citta)
        }
        if (this.$route.query.zona) {
            await this.getZoneFromApiId(this.$route.query.zona)
        }
        if (this.$route.query.cat) {
            this.selectCat = this.$route.query.cat.split(',').map((x) => {
                if (x == 'empty') {
                    return ''
                } else {
                    return x
                }
            })
        }
        if (this.$route.query.sli) {
            let sli = this.$route.query.sli.split(',')
            let c = 0
            for (const x in this.sliders) {
                this.sliders[x].value = sli[c]

                c++
            }
        }

        await this.getDataFromApi()
        await this.getProvinceFromApi('')
        await this.getCategoriesFromApi()
        //await this.getCitiesFromApi('')
        this.changinurl = false
    },
    methods: {
        clearFilters() {
            // Reset all filter
            this.searchProv = null
            this.selectProv = []
            this.searchCity = null
            this.selectCity = []
            this.selectCaps = []
            this.selectCat = []
            this.searchZona = null
            this.selectZona = []
            for (const x in this.sliders) {
                this.sliders[x].value = 0
            }
        },
        merge() {
            let errlead = false
            this.selectedLeads.forEach((x) => {
                if (
                    x.attributes.child_leads.data.filter((y) => {
                        return y.attributes.disabled == false
                    }).length
                ) {
                    errlead = true
                }
            })
            if (errlead) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('impossibile-unificare-dei-lead-con-derivati-unificare-prima-i-derivati'),
                    type: 'error'
                })
                return
            }
            this.mergeLeads = this.selectedLeads.map((x) => x.id)
            this.openmerge = true
        },
        addTo(id) {
            if (!id) {
                this.addto = this.selectedLeads.map((x) => x.id)
            } else {
                this.addto = [id]
            }
        },
        async updateUrl() {
            if (this.changinurl) return

            let query = { ...this.$route.query }
            this.changinurl = true
            if (this.options.page > 1) {
                if (this.$route.query.page != this.options.page) {
                    query.page = this.options.page
                }
            } else {
                delete query.page
            }
            if (this.options.itemsPerPage > 10) {
                if (this.$route.query.items != this.options.itemsPerPage) {
                    query.items = this.options.itemsPerPage
                }
            } else {
                delete query.items
            }
            if (this.options.sortBy.length) {
                if (this.$route.query.sortBy != this.options.sortBy) {
                    query.sortBy = this.options.sortBy
                    if (this.options.sortDesc.length && this.$route.query.sortDesc != this.options.sortDesc) {
                        query.sortDesc = this.options.sortDesc
                    }
                }
            } else {
                delete query.sortBy
                delete query.sortDesc
            }

            if (this.selectProv.length) {
                if (this.$route.query.provincia != this.selectProv.map((x) => x.id).join(',')) {
                    query.provincia = this.selectProv.map((x) => x.id).join(',')
                }
            } else {
                delete query.provincia
            }

            if (this.selectZona.length) {
                if (this.$route.query.zona != this.selectZona.map((x) => x.id).join(',')) {
                    query.zona = this.selectZona.map((x) => x.id).join(',')
                }
            } else {
                delete query.zona
            }

            if (this.selectCity.length) {
                if (this.$route.query.citta != this.selectCity.map((x) => x.id).join(',')) {
                    query.citta = this.selectCity.map((x) => x.id).join(',')
                }
            } else {
                delete query.citta
            }

            if (this.selectCaps.length) {
                if (this.$route.query.cap != this.selectCaps.join(',')) {
                    query.cap = this.selectCaps.join(',')
                }
            } else {
                delete query.cap
            }
            if (this.selectCat.length) {
                if (
                    this.$route.query.cat !=
                    this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                ) {
                    query.cat = this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                }
            } else {
                delete query.cat
            }
            let sli = []
            let found = false
            for (const x in this.sliders) {
                if (this.sliders[x].value > 0) {
                    found = true
                }
                sli.push(this.sliders[x].value)
            }
            if (found) {
                if (this.$route.query.sli != sli.join(',')) {
                    query.sli = sli.join(',')
                }
            } else {
                delete query.sli
            }

            this.$router
                .replace({
                    path: this.$route.path,
                    query: query
                })
                .catch((err) => {})

            await this.getDataFromApi()
            this.changinurl = false
        },
        async getCategoriesFromApi() {
            const p = await this.$http.get(`categories`, {
                'pagination[pageSize]': 200,
                'sort': 'name'
            })
            if (p.data.length) {
                this.categorie = p.data.map((x) => {
                    return { id: x.id, value: x.attributes.name, name: x.attributes.name }
                })
                this.categorie.push({ id: null, value: '', name: 'Senza Categoria' })
            }
        },
        getProvince(val) {
            /*if (val.length < 2) {
                return
            }*/
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getProvinceFromApi(val), 300)
        },
        async getProvinceFromApi(val) {
            this.loadingProv = true
            const p = await this.$http.get(`provinces`, {
                'pagination[pageSize]': 200,
                'populate': ['zones'],
                'filters': { Name: { $containsi: val } }
            })
            if (!p.data.length) {
                this.loadingProv = false
                return
            }
            this.Province = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })

            this.loadingProv = false
        },
        async getProvinceFromApiId(val) {
            this.loadingProv = true
            let filters = {}
            let c = 0
            val.split(',').forEach((x) => {
                filters[`filters[$or][${c}][id][$eq]`] = x
                c++
            })
            const p = await this.$http.get(`provinces`, {
                'pagination[pageSize]': 200,
                ...filters
            })
            if (!p.data.length) {
                this.loadingProv = false
                return
            }
            this.Province = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.selectProv = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingProv = false
        },
        getZone(val) {
            /*if (val.length < 2) {
                return
            }*/
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getZoneFromApi(val), 300)
        },
        async getZoneFromApi(val) {
            this.loadingZona = true
            const p = await this.$http.get(`zones`, {
                'pagination[pageSize]': 200,
                'filters': { Name: { $containsi: val } }
            })
            if (!p.data.length) {
                this.loadingZona = false
                return
            }
            this.Zone = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })

            this.loadingZona = false
        },
        async getZoneFromApiId(val) {
            this.loadingZona = true
            let filters = {}
            let c = 0
            val.split(',').forEach((x) => {
                filters[`filters[$or][${c}][id][$eq]`] = x
                c++
            })
            const p = await this.$http.get(`zones`, {
                'pagination[pageSize]': 200,
                ...filters
            })
            if (!p.data.length) {
                this.loadingZona = false
                return
            }
            this.Zone = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.selectZona = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingZona = false
        },
        getCities(val) {
            /*if (val.length < 2) {
                return
            }*/
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getCitiesFromApi(val), 300)
        },
        async getCitiesFromApi(val) {
            this.loadingCity = true
            this.Cities = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`cities`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'Name',
                    'filters': { Name: { $containsi: val } }
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            if (!c.data.length) {
                this.loadingCity = false
                return
            }
            this.Cities = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingCity = false
        },
        async getCitiesFromApiId(val) {
            this.loadingCity = true
            let filters = {}
            let c = 0
            val.split(',').forEach((x) => {
                filters[`filters[$or][${c}][id][$eq]`] = x
                c++
            })
            let ci = []
            let cc = {}
            let pa = 1
            do {
                cc = await this.$http.get(`cities`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    ...filters
                })
                ci = ci.concat(cc.data)
                pa++
            } while (cc.meta.pagination.pageCount > cc.meta.pagination.page)
            if (!cc.data.length) {
                this.loadingCity = false
                return
            }
            this.Cities = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.selectCity = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingCity = false
        },
        addCap() {
            if (!this.cap) return
            this.selectCaps.push(this.cap)
            this.cap = null
        },
        removeCap(i) {
            this.selectCaps.splice(i, 1)
        },
        setFilter() {
            this.options.page = 1
            this.updateUrl()
            this.modalFilter = false
        },

        async confirmExportLeads() {
            if (this.totalLeads >= this.limitNumberExport) {
                this.showExportConfirmation = true
            } else {
                await this.exportLeads()
            }
        },

        async exportLeads() {
            try {
                this.loading = true
                const { sortBy, sortDesc, page, itemsPerPage } = this.options

                const filters = this.filterapi()

                let sort = 'Name'
                if (sortBy.length) {
                    const el = this.headers.find((x) => x.value == sortBy[0])
                    sort = el.field.concat(sortDesc[0] ? ':desc' : '')
                }

                let csv = `${this.headers.map((x) => x.text).join(';')}\n`

                let cc = {}
                let pa = 1
                do {
                    cc = await this.$http.get('leads', {
                        'pagination[pageSize]': 200,
                        'pagination[page]': pa,
                        'populate': ['Province', 'City', 'zones', 'Social'],
                        'sort': sort,
                        ...filters
                    })
                    //esportare i dati salvarti nel template del file
                    cc.data.forEach((x) => {
                        if (!x) return
                        let row = ''
                        this.headers.forEach((h) => {
                            let t = this.$getObjValue(x, h.value)
                            if (h.value == 'attributes.zones.data') {
                                t = t.map((x) => x.attributes.Name).join(',')
                            }
                            row += `${t == null ? '' : t};`
                        })

                        csv += `${row.slice(0, -1)}\n`
                    })
                    pa++
                } while (cc.meta.pagination.pageCount > cc.meta.pagination.page)

                this.loading = false
                this.showExportConfirmation = false

                const anchor = document.createElement('a')
                anchor.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
                anchor.target = '_blank'
                anchor.download = `marketing-${this.$moment().format('YYMMDD')}.csv`
                anchor.click()
            } catch (error) {
                /*this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-durante-la-richiesta-di-importazione-dei-leads'),
                    type: 'error'
                })*/
                console.error(error)
            }
        },
        filterapi() {
            let filters = {}

            filters[`filters[$and][0][disabled][$eq]`] = false
            let cc = 1

            if (this.selectProv.length) {
                let c = 0
                this.selectProv.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][Province][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            if (this.selectCity.length) {
                let c = 0
                this.selectCity.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][City][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            if (this.selectCaps?.length) {
                let c = 0
                this.selectCaps.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][CAP][$eq]`] = x
                    c++
                })
                cc++
            }
            if (this.selectCat?.length) {
                let c = 0
                this.selectCat.forEach((x) => {
                    if (x == '') {
                        filters[`filters[$and][${cc}][$or][${c}][Category][$null]`] = true
                        c++
                    }
                    filters[`filters[$and][${cc}][$or][${c}][Category][$eq]`] = x
                    c++
                })
                cc++
            }
            if (this.selectZona.length) {
                let c = 0
                this.selectZona.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][zones][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            for (const x in this.sliders) {
                if (this.sliders[x].value > 0) {
                    filters[
                        `filters[$and][${cc}][$${this.sliders[x].value == 1 ? 'and' : 'or'}][0]${this.sliders[x].field}[$${
                            this.sliders[x].value == 1 ? 'notNull' : 'null'
                        }]`
                    ] = true
                    filters[
                        `filters[$and][${cc}][$${this.sliders[x].value == 1 ? 'and' : 'or'}][1]${this.sliders[x].field}[$${
                            this.sliders[x].value == 1 ? 'ne' : 'eq'
                        }]`
                    ] = ''
                    cc++
                }
            }
            return filters
        },
        async getDataFromApi() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.options

            const filters = this.filterapi()

            let sort = 'Name'
            if (sortBy.length) {
                const el = this.headers.find((x) => x.value == sortBy[0])
                sort = el.field.concat(sortDesc[0] ? ':desc' : '')
            }
            const docs = await this.$http.get('leads', {
                'pagination[pageSize]': itemsPerPage,
                'pagination[page]': page,
                'populate': ['Province', 'City', 'zones', 'Social'],
                'sort': sort,
                ...filters
            })

            this.leads = docs.data || docs
            this.totalLeads = docs.meta.pagination.total ? docs.meta.pagination.total : 0
            this.loading = false
        }
    }
}
</script>
<style>
/* Stile CSS personalizzato */
.no-underline {
    text-decoration: none;
}
.icon {
    width: 20px;
}
h4 {
    text-align: center;
    text-transform: uppercase;
}
</style>
